import {Route, Role, DecisionType} from '../../interface/Types';
import Home from '../../pages/Layout';
import Login from '../../pages/Login';
import Dashboard  from '../../pages/Dashborad';
import RolePage from '../../pages/arrangement/Role'
import Privilege  from '../../pages/arrangement/Priviliege';
import RolesandPrivilege from '../../pages/arrangement/RolesandPrivileges';
import User from '../../pages/User'
import Event from '../../pages/Event';
import price from '../../pages/membership/Price';
import Membership from '../../pages/membership/Membership';
import MembershipRelation from '../../pages/membership/MembershipRelation';
import ReadingA from '../../pages/Test/Reading';
import ReadingB from '../../pages/Test/Listening';
import Translation from '../../pages/Test/Translation';
import Vocabulary from '../../pages/Test/Vocabulary';
import Writing from '../../pages/Test/Writing';
import SetQuestion from '../../pages/QuestionManagement/RealTest/SetQuestionManage';
import RealTestManagement from '../../pages/QuestionManagement/RealTest/RealTestManagement';
import QuestionDetail from '../../pages/QuestionManagement/RealTest/QuestionDetial';
import Self_innovate from '../../pages/QuestionManagement/Self-innovate-test/Self_innovate';
import Question from '../../pages/Question/Question';
import QuestionPaperTable from '../../pages/QuestionPaper/QuestionPaper';
const routes: Route[] = [
  {
    path: '/',
    component: Home,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    },
  },
  
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    },
  },
  {
    path: '/login',
    component: Login,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    },
  },
  {
    path: '/users',
    component: User,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path : '/roles',
    component: RolePage,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path : '/privileges',
    component: Privilege,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  }
  ,
  {
    path : '/rolesandprivileges',
    component: RolesandPrivilege,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path : '/event',
    component: Event,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path : '/price',
    component: price,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW }, 
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path : '/Membership',
    component: Membership,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path : '/MembershipRelation',
    component: MembershipRelation,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path : '/ReadingA',
    component: ReadingA,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },

      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path : '/ReadingB',
    component : ReadingB,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path : '/Translation',
    component : Translation,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    },

  },
  {
    path : '/Vocabulary',
    component : Vocabulary,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path : '/Writing',
    component : Writing,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },

      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path : '/RealTestManagement',
    component : RealTestManagement,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path: '/SetQuestion',
    component: SetQuestion,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path: '/QuestionDetail',
    component: QuestionDetail,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path: '/Self_innovate',

    component: Self_innovate,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path: '/Question',

    component: Question,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  },
  {
    path: '/QuestionPaper',

    component: QuestionPaperTable,
    exact: true,
    acl: {
      [Role.staff]: { type: DecisionType.ALLOW },
      [Role.admin]: { type: DecisionType.ALLOW },
      [Role.guest]: { type: DecisionType.ALLOW},
    }
  }
];
export default routes;
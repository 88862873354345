import React, { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';
import { Toolbar, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { TableRow } from '../interface/Types';

interface RoleGridProps {
  userRole: string;
  fetchData: () => Promise<TableRow[]>;
  deleteData: (id: GridRowId) => Promise<void>;
  saveData: (updatedRow: TableRow) => Promise<TableRow | { error: string }>;
  columns: GridColDef[];
  title: string;
}

const CrudGrid: FC<RoleGridProps> = ({ userRole, fetchData, deleteData, saveData, columns, title }) => {
  const [rows, setRows] = useState<TableRow[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [open, setOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState<'add' | 'edit'>('add');
  const [selectedRow, setSelectedRow] = useState<TableRow | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<{ open: boolean; id: GridRowId | null }>({ open: false, id: null });

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const data = await fetchData();
        setRows(data);
      } catch (error) {
        console.error('Error fetching roles:', error);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleDialogOpen = (mode: 'add' | 'edit', row?: TableRow) => {
    setDialogMode(mode);
    setSelectedRow(row || { id: -1, isNew: true });
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setSelectedRow(null);
    setError(null);
  };

  const handleSave = async () => {
    if (selectedRow) {
      const result = await saveData(selectedRow);
      if ('error' in result) {
        setError(result.error);
        return;
      }
      const savedRow = result;
      setRows((prevRows) => {
        if (!Array.isArray(prevRows)) {
          return [savedRow];
        }
        const existingRow = prevRows.find((row) => row.id === savedRow.id);
        if (existingRow) {
          return prevRows.map((row) => (row.id === savedRow.id ? savedRow : row));
        } else {
          return [...prevRows, savedRow];
        }
      });
      handleDialogClose();
    }
  };
  
  const handleDeleteClick = (id: GridRowId) => () => {
    if (userRole !== 'admin' && userRole !== 'Admin') {
      alert('You do not have permission to delete.');
      return;
    }
    setConfirmDelete({ open: true, id });
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDelete({ open: false, id: null });
  };

  const handleConfirmDelete = async () => {
    if (confirmDelete.id !== null) {
      await deleteData(confirmDelete.id);
      setRows(rows.filter((row) => row.id !== confirmDelete.id));
    }
    handleConfirmDeleteClose();
  };

  const handleChange = (field: keyof TableRow) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) return;
    setSelectedRow((prevRow) => ({
      ...prevRow,
      [field]: event.target.value,
    }) as TableRow);
  };  

  const actionColumns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key={`edit-${id}`}
            label="Edit"
            className="textPrimary"
            onClick={() => handleDialogOpen('edit', rows.find((row) => row.id === id))}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key={`delete-${id}`}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <Button color="primary" startIcon={<AddIcon />} onClick={() => handleDialogOpen('add')}>
          Add New {title}
        </Button>
      </Toolbar>
      <DataGrid
        rows={rows}
        columns={[...columns, ...actionColumns]}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
      />
      <Dialog open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{dialogMode === 'add' ? 'Add New' : 'Edit'} {title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details for the {title.toLowerCase()}.
          </DialogContentText>
          {columns.map((col) => (
            col.field != 'id' && (
              <TextField
                key={col.field}
                autoFocus
                margin="dense"
                label={col.headerName}
                fullWidth
                value={selectedRow?.[col.field] || ''}
                onChange={handleChange(col.field as keyof TableRow)}
              />
            )
          ))}
          {error && <Typography color="error">{error}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDelete.open}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="confirm-delete-dialog-title"
      >
        <DialogTitle id="confirm-delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this {title.toLowerCase()}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CrudGrid;
import React, { useEffect, useState, useCallback } from 'react';
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { Button, IconButton, Paper, Typography, Box } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAuth } from '../../hooks/useAuth';
import FilterDialog from '../../components/FilterDialog';
import { QuestionPaper } from '../../interface/QuestionPaperTypes';
import QuestionsDialog from './QuestionsDialog';
import QuestionDetailDialog from './QuestionDetailDialog';
import { Question } from '../../interface/QuestionTypes';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import QuestionPaperFormDialog from './QuestionPaperFormDialog';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;
const QUESTION_PAPER_ENDPOINT = process.env.REACT_APP_QUESTION_PAPER_ENDPOINT;
const QUESTIONS_ENDPOINT = process.env.REACT_APP_QUESTIONS_ENDPOINT;

const QuestionPaperDataGrid = () => {
  const [questionPapers, setQuestionPapers] = useState<QuestionPaper[]>([]);
  const { user } = useAuth();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'id', sort: 'asc' }]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    id: true,
    created_at: false,
    updated_at: false,
    name: true,
    paper_type: true,
    grade_level: true,
    score: true,
    questions: true,
    question_count: true,
    random: true,
    suggest_time: true,
    limit_start_time: false,
    limit_end_time: false,
    actions: true,
  });

  const [openQuestionIdsDialog, setOpenQuestionIdsDialog] = useState(false);
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<number[]>([]);
  const [openQuestionDetailDialog, setOpenQuestionDetailDialog] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<Question>();
  const [formOpen, setFormOpen] = useState(false);
  const [formMode, setFormMode] = useState<'edit' | 'add'>('add');
  const [formInitialData, setFormInitialData] = useState<QuestionPaper | null>(null);

  // Memoize the fetchQuestionPaper function to avoid unnecessary re-creations
  const fetchQuestionPaper = useCallback(async () => {
    if (user) {
      try {
        const response = await fetch(`${BASE_URL}/${API_VERSION}/${QUESTION_PAPER_ENDPOINT}`, {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${user.access_token}`,
          },
        });
  
        if (response.ok) {
          const data: QuestionPaper[] = await response.json();
  
          const papersWithIds = data.map((paper, index) => ({
            ...paper,
            id: index,
          }));
  
          setQuestionPapers(papersWithIds);
        } else {
          console.error('Failed to fetch question papers:', response.statusText);
          alert(`Error fetching question papers: ${response.statusText}`);
        }
      } catch (error) {
        console.error('Error fetching question papers:', error);
        alert(`Error fetching question papers: ${error}`);
      }
    }
  }, [user]);
  
  useEffect(() => {
    fetchQuestionPaper();
  }, [fetchQuestionPaper]);  

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisibleColumns({
      ...visibleColumns,
      [event.target.name]: event.target.checked,
    });
  };

  const handleOpenQuestionIdsDialog = (questionIds: number[]) => {
    setSelectedQuestionIds(questionIds);
    setOpenQuestionIdsDialog(true);
  };

  const handleCloseQuestionIdsDialog = () => setOpenQuestionIdsDialog(false);

  const handleCloseQuestionDetailDialog = () => setOpenQuestionDetailDialog(false);

  const fetchQuestion = async (questionId: number) => {
    if (user) {
      try {
        const response = await fetch(`${BASE_URL}/${API_VERSION}/${QUESTIONS_ENDPOINT}/${questionId}`, {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${user.access_token}`,
          },
        });

        if (response.ok) {
          const question = await response.json();
          setSelectedQuestion(question);
          setOpenQuestionDetailDialog(true);
        } else {
          console.error('Failed to fetch question details:', response.statusText);
          alert(`Error fetching question details: ${response.statusText}`);
        }
      } catch (error) {
        console.error('Error fetching question details:', error);
        alert(`Error fetching question details: ${error}`);
      }
    }
  };

  const handleDialogOpen = (mode: 'edit' | 'add', data?: QuestionPaper) => {
    setFormMode(mode);
    setFormInitialData(data || null);
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setFormInitialData(null);
  };

  const handleFormSave = async (questionPaper: QuestionPaper) => {
    if (user) {
      try {
        // Define all the fields in request body
        const {
          id,
          name,
          paper_type,
          grade_level,
          score,
          questions,
          question_count,
          random,
          suggest_time,
          limit_start_time,
          limit_end_time,
        } = questionPaper;

        const requestBody = {
          id,
          name,
          paper_type,
          grade_level,
          score,
          questions,
          question_count,
          random,
          suggest_time,
          limit_start_time,
          limit_end_time,
        };

        let response = new Response;
        if (formMode === 'add') {
          response = await fetch(`${BASE_URL}/${API_VERSION}/${QUESTION_PAPER_ENDPOINT}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.access_token}`,
            },
            body: JSON.stringify(requestBody),
          });
        } else if (formMode === 'edit') {
          response = await fetch(`${BASE_URL}/${API_VERSION}/${QUESTION_PAPER_ENDPOINT}/${questionPaper.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.access_token}`,
            },
            body: JSON.stringify(requestBody),
          });
        }

        if (!response.ok) {
          const responseBody = await response.json();
          console.error(`Failed to ${formMode === 'add' ? 'create' : 'update'} question paper:`, responseBody);
          alert(`Error ${formMode === 'add' ? 'creating' : 'updating'} question paper: ${response.statusText}`);
        } else {
          await fetchQuestionPaper(); // Refresh the data after successful save
          setFormOpen(false);
          setFormInitialData(null);
        }
      } catch (error) {
        console.error('Error saving question paper:', error);
        alert(`Error saving question paper: ${error}`);
      }
    }
  };

  const handleDeleteClick = async (id: GridRowId) => {
    if (user) {
      try {
        const response = await fetch(`${BASE_URL}/${API_VERSION}/${QUESTION_PAPER_ENDPOINT}/${id}`, {
          method: 'DELETE',
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${user.access_token}`,
          },
        });

        if (response.ok) {
          await fetchQuestionPaper(); // Refresh the data after deletion
        } else {
          console.error('Failed to delete question paper:', response.statusText);
          alert(`Error deleting question paper: ${response.statusText}`);
        }
      } catch (error) {
        console.error('An unexpected error occurred:', error);
        alert('An unexpected error occurred while deleting the question paper. Please try again later.');
      }
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'created_at', headerName: 'Created At', width: 150 },
    { field: 'updated_at', headerName: 'Updated At', width: 150 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'paper_type', headerName: 'Type', width: 150 },
    { field: 'grade_level', headerName: 'Grade Level', width: 130 },
    { field: 'score', headerName: 'Score', width: 110 },
    {
      field: 'questions',
      headerName: 'Questions',
      width: 180,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => handleOpenQuestionIdsDialog(params.row.questions)}
        >
          View Questions
        </Button>
      ),
    },
    { field: 'question_count', headerName: 'Question Count', width: 130 },
    { field: 'random', headerName: 'Random', width: 100 },
    { field: 'suggest_time', headerName: 'Suggested Time', width: 150 },
    { field: 'limit_start_time', headerName: 'Start Time', width: 150 },
    { field: 'limit_end_time', headerName: 'End Time', width: 150 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          key={`edit-${id}`}
          label="Edit"
          className="textPrimary"
          onClick={() => handleDialogOpen('edit', questionPapers.find((row) => row.id === id))}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          key={`delete-${id}`}
          label="Delete"
          onClick={() => handleDeleteClick(id)}
          color="inherit"
        />,
      ],
    },
  ];

  const visibleColumnsFiltered = columns.filter(column => visibleColumns[column.field as keyof typeof visibleColumns]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h6" component="div">
          Question Paper
        </Typography>
        <Button color="primary" startIcon={<AddIcon />} onClick={() => handleDialogOpen('add')}>
          Add New Question Paper
        </Button>
      </Box>
      <IconButton onClick={() => setFilterOpen(true)}>
        <FilterListIcon />
      </IconButton>
      <FilterDialog open={filterOpen} onClose={() => setFilterOpen(false)} visibleColumns={visibleColumns} handleFilterChange={handleFilterChange} />
      <Paper style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={questionPapers}
          columns={visibleColumnsFiltered}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </Paper>

      <QuestionsDialog
        open={openQuestionIdsDialog}
        onClose={handleCloseQuestionIdsDialog}
        questionIds={selectedQuestionIds}
        onViewQuestion={fetchQuestion}
      />

      <QuestionDetailDialog
        open={openQuestionDetailDialog}
        onClose={handleCloseQuestionDetailDialog}
        question={selectedQuestion}
      />

      <QuestionPaperFormDialog
        open={formOpen}
        onClose={handleFormClose}
        onSave={handleFormSave}
        initialData={formInitialData}
        mode={formMode}
      />
    </>
  );
};

export default QuestionPaperDataGrid;

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { Toolbar, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material';
import { Role } from '../../interface/Types';
import { useAuth } from '../../hooks/useAuth';

const initialRows: GridRowsProp = [
  {
    id: 1,
    year: 'NA',
    month: 'NA',
    level: 'NA',
    paper: 'NA',
    accuracyRate: '',
  },
  {
    id: 2,
    year: 'NA',
    month: 'NA',
    level: 'NA',
    paper: 'NA',
    accuracyRate: '',
  },
  {
    id: 3,
    year: 'NA',
    month: 'NA',
    level: 'NA',
    paper: 'NA',
    accuracyRate: '',
  },
];

const initialQuestions: GridRowsProp = [
  {
    id: 1,
    question: '请为这个句子填上合适的连接词：The rice cake has not only good meanings but also rich nutrition, ___ enables it to be one of the necessary festival foods.',
    optionA: 'NA',
    optionB: 'NA',
    optionC: 'NA',
    optionD: 'NA',
    isMultipleChoice: false,
    type: '翻译逻辑',
    questionType: '写译',
    answer: 'which',
    explanation: '',
    commonMistake: '',
  },
  {
    id : 2,
    question: '请找出一下句子的谓语',
    optionA: '年糕（rice cake）表达人们对未来一年的期待',
    optionB: '年糕',
    optionC: '表达',
    optionD: '期待',
    isMultipleChoice: false,
    type: '翻译表达',
    questionType: '写译',
    answer: 'B',
    explanation: '',
    commonMistake: '',
  },
    {
        id: 3,
        question: '下列翻译排序正确的是哪一个',
        optionA: '1. symbolizes 2. safety and reunion 3.the rice cake',
        optionB: '213',
        optionC: '123',
        optionD: '312',
        isMultipleChoice: false,
        type: '翻译表达',
        questionType: '写译',
        answer: 'C',
        explanation: '',
        commonMistake: '',
    }
];

const Translation: React.FC = () => {
  const [rows, setRows] = React.useState(initialRows);
  const [questions, setQuestions] = React.useState(initialQuestions); 
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [open, setOpen] = React.useState(false);
  const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<GridRowId | null>(null);
  const [updateQuestionGrid, setupdateQuestionGrid] = React.useState<GridRowsProp>([]);
  const { user } = useAuth();

  const handleAlert = (id: GridRowId) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = (confirmed: boolean) => {
    if (confirmed && selectedId !== null) {
      setRows(rows.filter((row) => row.id !== selectedId));
    }
    setOpen(false);
    setSelectedId(null);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to edit.');
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to save changes.');
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to delete.');
      return;
    }
    handleAlert(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const processQuestionRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setQuestions(questions.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleAddClick = () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to add.');
      return;
    }
    const id = rows.length ? rows[rows.length - 1].id + 1 : 1;
    setRows([...rows, { id, year: 'NA', month: 'NA', level: 'NA', paper: 'NA', question: '', optionA: '', optionB: '', optionC: '', optionD: '', isMultipleChoice: false, type: '', questionType: '', answer: '', explanation: '', commonMistake: '', accuracyRate: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'RoleName' },
    }));
  };

  const handleDetailClick = (id: number) => {
    let filteredQuestion = questions.filter((question) => question.id === id);
    if (filteredQuestion.length === 0) {
        filteredQuestion = [{
            id: id,
            question: '',
            optionA: '',
            optionB: '',
            optionC: '',
            optionD: '',
            optionE: '',
            isMultipleChoice: false,
            type: '',
            questionType: '',
            article: '',
            answer: '',
            explanation: '',
            commonMistake: ''
          }];
    }
    setupdateQuestionGrid(filteredQuestion);
    setQuestionDetailOpen(true);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'year', headerName: '年', width: 100, editable: true, type: 'singleSelect', valueOptions: ['NA'] },
    { field: 'month', headerName: '月', width: 100, editable: true ,type: 'singleSelect', valueOptions: ['NA'] },
    { field: 'level', headerName: '级别', width: 100, editable: true ,type: 'singleSelect', valueOptions: ['NA']},
    { field: 'paper', headerName: '卷', width: 100, editable: true },
    { field: 'accuracyRate', headerName: '全站正确率(%)', width: 150, editable: true, type: 'number'},
    { 
      field: 'question_detial', 
      headerName: '题目详情', 
      width: 100, 
      editable: false,
      renderCell: (params) => {
        const id = params.row.id;
        return <Button onClick={() => handleDetailClick(id)}>查看</Button>;
      }
    },
    {
      field: '修改/删除',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key={`save-${id}`}
              label="Save"
              sx={{ color: 'primary.main' }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              key={`cancel-${id}`}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key={`edit-${id}`}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key={`delete-${id}`}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const questionColumns: GridColDef[] = [
    { field: 'question', headerName: '题干', width: 300, editable: true },
    { field: 'optionA', headerName: '选项A', width: 200, editable: true },
    { field: 'optionB', headerName: '选项B', width: 200, editable: true },
    { field: 'optionC', headerName: '选项C', width: 200, editable: true },
    { field: 'optionD', headerName: '选项D', width: 200, editable: true },
    { field: 'optionE', headerName: '选项E', width: 200, editable: true },
    { field: 'isMultipleChoice', headerName: '是否多选', width: 150, editable: true, type: 'boolean' },
    { field: 'type', headerName: '类型', width: 150, editable: true },
    { field: 'questionType', headerName: '题型', width: 150, editable: true },
    { field: 'article', headerName: '文章', width: 300, editable: true },
    { field: 'answer', headerName: '答案', width: 100, editable: true },
    { field: 'explanation', headerName: '解析', width: 300, editable: true },
    { field: 'commonMistake', headerName: '易错项', width: 150, editable: true, type: 'singleSelect', valueOptions: ['A','B','C','D'] },
  ];

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          翻译题目管理
        </Typography>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
          添加新的翻译题目
        </Button>
      </Toolbar>
      <Paper sx={{ width: '100%', overflowX: 'auto' }}>
        <Box sx={{ minWidth: 1200, height: 500 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onRowModesModelChange={handleRowModesModelChange}
            getRowId={(row) => row.id}
          />
        </Box>
      </Paper>
      <Dialog open={questionDetailOpen} onClose={() => setQuestionDetailOpen(false)} maxWidth="xl" fullWidth>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">题目详情</Typography>
            <Button onClick={() => setQuestionDetailOpen(false)} color="primary" sx={{ padding: '10px 20px', fontSize: '1rem' }}>
            保存
            </Button>
          </Box>
        </DialogTitle>    
        <Paper sx={{ overflowX: 'auto' }}>
          <Box sx={{ width: '100%', height: 800 }}>
            <DataGrid
              rows={updateQuestionGrid}
              columns={questionColumns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processQuestionRowUpdate}
              onRowModesModelChange={handleRowModesModelChange}
              getRowId={(row) => row.id}
            />
          </Box>
        </Paper>
        <DialogActions>
          <Button onClick={() => setQuestionDetailOpen(false)} color="primary">
            关闭
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>确认删除</DialogTitle>
        <DialogContent>
          <DialogContentText>你确定要删除这个题目吗？此操作无法撤销。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>取消</Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Translation;

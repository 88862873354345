import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import { Box, Card, CardContent, Typography } from '@mui/material';

interface LineChartProps {
  data: number[];
  labels: string[];
}
Chart.register(...registerables);

const LineChart: React.FC<LineChartProps> = ({ data, labels }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        const config: ChartConfiguration = {
          type: 'line',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Sample Data',
                data: data,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                fill: true,
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'X Axis',
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'Y Axis',
                },
              },
            },
          },
        };

        new Chart(ctx, config);
      }
    }
  }, [data, labels]);

  return <canvas ref={chartRef} />;
};

const Dashboard: React.FC = () => {
  const data = [65, 59, 80, 81, 56, 55, 40];
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
      <Card sx={{ width: '80%' }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Line Chart Example
          </Typography>
          <LineChart data={data} labels={labels} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default Dashboard;

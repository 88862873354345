import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Paper, Typography, Button } from '@mui/material';
import { DataGrid, GridColDef, GridRowModesModel, GridRowsProp } from '@mui/x-data-grid';

const Innovate_QuestionDetail: React.FC = () => {
  const location = useLocation();
  const { initialRows, initialColumns, rowModesModel } = location.state as {
    initialRows: GridRowsProp;
    initialColumns: GridColDef[];
    rowModesModel: GridRowModesModel;
  };
  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        题目详情
      </Typography>
      <Paper sx={{ width: '100%', overflowX: 'auto' }}>
        <Box sx={{ minWidth: 1200, height: 500 }}>
          <DataGrid
            rows={initialRows}
            columns={initialColumns}
            editMode="row"
            rowModesModel={rowModesModel}
            getRowId={(row) => row.id}
          />
        </Box>
      </Paper>
      <Button onClick={() => window.history.back()} color="primary" sx={{ marginTop: 2 }}>
        返回
      </Button>
    </Box>
  );
};

export default Innovate_QuestionDetail;

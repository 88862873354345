import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { Toolbar, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Paper, DialogContentText, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Role } from '../../interface/Types';
import { useAuth } from '../../hooks/useAuth';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const initialRows: GridRowsProp = [
  { id: 1, year: 2021, month: 12, level: 4, paper: 2, question_number: 2, question_type: "阅读理解",accuracyRate: '60' },
  { id: 2, year: 2021, month: 12, level: 4, paper: 3, question_number: 3, question_type: "仔细阅读",accuracyRate: '60' },
  { id: 3, year: 2016, month: 12, level: 4, paper: 1, question_number: 2, question_type: "仔细阅读",accuracyRate: '34' },
  { id: 4, year: 2019, month: 11, level: 4, paper: 2, question_number: 2, question_type: "阅读填空",accuracyRate: '34' },
  { id: 5, year: 2039, month: 2, level: 5, paper: 3,question_number: 1, question_type: "阅读填空", accuracyRate: '34' },
  { id: 6, year: 2021, month: 11, level: 4, paper: 2, question_number: 2, question_type: "阅读理解",accuracyRate: '60' },
];

const initialQuestions: GridRowsProp = [
  {
    id: 1,
    question: '选出单词的正确词性',
    optionA: 'captured',
    optionB: '名词',
    optionC: '动词',
    optionD: '形容词',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '词性辨析',
    questionType: '选词填空',
    article: '<p>NA</p>',
    answer: 'A',
    explanation: '<p></p>',
    commonMistake: '',
  },
  {
    id: 2,
    question: '选出单词的正确词性',
    optionA: 'hwllowrold',
    optionB: '名词',
    optionC: '动词',
    optionD: '形容词',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '词性辨析',
    questionType: '选词填空',
    article: '<p>NA</p>',
    answer: 'A',
    explanation: '<p></p>',
    commonMistake: '',
  },
  {
    id: 3,
    question: '选出单词的正确词性',
    optionA: 'captured',
    optionB: '名词',
    optionC: '动词',
    optionD: '形容词',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '词性辨析',
    questionType: '选词填空',
    article: '<p>NA</p>',
    answer: 'A',
    explanation: '<p></p>',
    commonMistake: '',
  },
  {
    id: 4,
    question: '选出单词的正确词性',
    optionA: 'Falling into a routine can turn a person into an utter bore.',
    optionB: 'Falling into a routine',
    optionC: 'a person',
    optionD: 'an utter bore',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '词性辨析',
    questionType: '选词填空',
    article: '<p>NA</p>',
    answer: 'B',
    explanation: '<p></p>',
    commonMistake: 'C',
  },
  {
    id: 5,
    question: '请选择与题干下划线处意思相近的表达',
    optionA: 'Worst of all, a cheater who doesn\'t get caught the first time usually cheats again. (Worst of all)',
    optionB: 'what\'s worse',
    optionC: 'first of all',
    optionD: 'what\'s more',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '同义替换',
    questionType: '选词填空',
    article: '<p>NA</p>',
    answer: 'C',
    explanation: '<p></p>',
    commonMistake: 'D',
  },
  {
    id: 6,
    question: '请选择和题干内容相一致的段落',
    optionA: 'Receiving education in a private university in the nation\'s captial may change the author\'s future life and that of her family\'s.',
    optionB: 'A',
    optionC: 'B',
    optionD: 'C',
    optionE: 'D',
    isMultipleChoice: false,
    type: '句义理解',
    questionType: '信息匹配',
    article: '<p>NA</p>',
    answer: 'A',
    explanation: '<p>我也不知道</p>',
    commonMistake: 'B',
  },
  {
    id: 7,
    question: '选出单词的同义词',
    optionA: 'how close ... relationships are',
    optionB: 'level of intimacy',
    optionC: 'how far ... the place is',
    optionD: 'level of significance',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '同义替换',
    questionType: '仔细阅读',
    article: '<p>NA</p>',
    answer: 'B',
    explanation: '<p></p>',
    commonMistake: '',
  },
  {
    id: 8,
    question: '请根据材料选择正确答案',
    optionA: 'What is the finding of the new study?',
    optionB: 'Emotional intelligence helps people to get along with others.',
    optionC: 'Emotional intelligence is generally beneficial to its bearer.',
    optionD: 'People who are aware of others\' feelings may experience more stress.',
    optionE: 'People who are emotionally stressed may have trouble making friends.',
    isMultipleChoice: false,
    type: '推断判断',
    questionType: '仔细阅读',
    article: 'Recognizing when a friend or colleague feels sad...',
    answer: 'A',
    explanation: '<p></p>',
    commonMistake: '',
  },
  {
    id: 9,
    question: '请根据所给材料判断题干是否正确',
    optionA: 'The fish we eat is remarkable in they reproduce quickly.',
    optionB: '正确',
    optionC: '错误',
    optionD: 'NA',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '句义理解',
    questionType: '仔细阅读',
    article: 'What\'s really remarkable, thought,...',
    answer: 'A',
    explanation: '<p></p>',
    commonMistake: '',
  },
];

const ReadingA: React.FC = () => {
  const [rows, setRows] = React.useState(initialRows);
  const [questions] = React.useState(initialQuestions); 
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [open, setOpen] = React.useState(false);
  const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<GridRowId | null>(null);
  const [updateQuestionGrid, setUpdateQuestionGrid] = React.useState<GridRowsProp>([]);
  const { user } = useAuth();
  const [handleYearFilter, sethandleYearFilter] = React.useState(false);
  const [selectedYears, setSelectedYears] = React.useState<number[]>([]);
  const [handleMonthFilter, sethandleMonthFilter] = React.useState(false);
  const [selectedMonths, setSelectedMonths] = React.useState<number[]>([]);
  const [handleLevelFilter, sethandleLevelFilter] = React.useState(false);
  const [selectedLevels, setSelectedLevels] = React.useState<number[]>([]);
  const [handleRollFilter, sethandleRollFilter] = React.useState(false);
  const [selectedRolls, setSelectedRolls] = React.useState<number[]>([]);
  const [article, setArticle] = React.useState<string>('');
  const [explanation, setExplanation] = React.useState<string>('');

  /* 从这里开始是对DATAGRID的增删查改的所有操作 */
  const handleAlert = (id: GridRowId) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = (confirmed: boolean) => {
    if (confirmed && selectedId !== null) {
      setRows(rows.filter((row) => row.id !== selectedId));
    }
    setOpen(false);
    setSelectedId(null);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to edit.');
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to save changes.');
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to delete.');
      return;
    }
    handleAlert(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };


  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleAddClick = () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to add.');
      return;
    }
    const id = rows.length ? rows[rows.length - 1].id + 1 : 1;
    setRows([...rows, { id, year: 0, month: 0, level: 0, paper: 0, question_number: '',question_type: '',question: '', optionA: '', optionB: '', optionC: '', optionD: '', optionE: '', isMultipleChoice: false, type: '', questionType: '', answer: '', explanation: '', commonMistake: '', accuracyRate: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'RoleName' },
    }));
  };

  const handleDetailClick = (id: number) => {
    let filteredQuestion = questions.filter((question) => question.id === id);
    if (filteredQuestion.length === 0) {
        filteredQuestion = [{
            id: id,
            question: '',
            optionA: '',
            optionB: '',
            optionC: '',
            optionD: '',
            optionE: '',
            isMultipleChoice: false,
            type: '',
            questionType: '',
            article: '',
            answer: '',
            explanation: '',
            commonMistake: ''
          }];
    }
    setUpdateQuestionGrid(filteredQuestion);
    setArticle(filteredQuestion[0].article);
    setExplanation(filteredQuestion[0].explanation);
    setQuestionDetailOpen(true);
  };
//----------到这里结束对DATAGRID的操作--------------------------------------------------------------------------------

/* 从这里开始是对过滤年份的操作 */

  const handleOpenYearDialog = () => {
    sethandleYearFilter(true);
  };

  const handleCloseYearDialog = () => {
    sethandleYearFilter(false);
  }
  const handleYearClick = (year: number) => {
    setSelectedYears((prev) =>
      prev.includes(year) ? prev.filter((y) => y !== year) : [...prev, year]
    );
  };

  const handleFilterYearApply = () => {
    sethandleYearFilter(false);
    if (selectedYears.length > 0) {
      const filteredRows = initialRows.filter((row) => selectedYears.includes(row.year));
      setRows(filteredRows);
    } else {
      setRows(initialRows);
    }
  };
//----------到这里结束--------------------------------------------------------------------------------


/* 从这里开始是对过滤月份的操作 */
  const handleOpenMonthDialog = () => {
    sethandleMonthFilter(true);
  };
  const handleCloseMonthDialog = () => {
    sethandleMonthFilter(false);
  };

  const handleMonthClick = (month: number) => {
    setSelectedMonths((prev) =>
      prev.includes(month) ? prev.filter((y) => y !== month) : [...prev, month]
    );
  };

  const handleFilterMonthApply = () => {
    sethandleMonthFilter(false);
    if (selectedMonths.length > 0) {
      const filteredRows = initialRows.filter((row) => selectedMonths.includes(row.month));
      setRows(filteredRows);
    } else {
      setRows(initialRows);
    }
  };

//----------到这里结束对月份过滤的操作--------------------------------------------------------------------------------


/* 从这里开始是对过滤级别的操作 */

const handleOpenLevelDialog = () => {
  sethandleLevelFilter(true);
};

const handleCloseLevelDialog = () => {
  sethandleLevelFilter(false);
};

const handleLevelFilterApply = () => {
  sethandleLevelFilter(false)
  if(selectedLevels.length > 0){
    const filteredRows = initialRows.filter((row) => selectedLevels.includes(row.level))
    setRows(filteredRows)
  }
  else{
    setRows(initialRows)
  }
};

const handleLevelClick = (level: number) => {
  setSelectedLevels((pre) => 
    pre.includes(level) ? pre.filter((l) => l !== level) : [...pre, level]
  ) 
}

//----------到这里结束对级别过滤的操作--------------------------------------------------------------------------------


/* 从这里开始是对过滤卷的操作 */
const handleOpenRollDialog = () => {
  sethandleRollFilter(true);
};

const handleCloseRollDialog = () => {
  sethandleRollFilter(false);
};

const handleRollClick = (roll: number) => {
  setSelectedRolls((prev) =>
    prev.includes(roll) ? prev.filter((y) => y !== roll) : [...prev, roll]
  );
};

const handleFilterRollApply = () => {
  sethandleRollFilter(false);
  if (selectedRolls.length > 0) {
    const filteredRows = initialRows.filter((row) => selectedRolls.includes(row.paper));
    setRows(filteredRows);
  } else {
    setRows(initialRows);
  }
};
//----------到这里结束对卷过滤的操作--------------------------------------------------------------------------------



const handleCancelAllFilters = () => {
  setSelectedYears([]);
  setSelectedMonths([]);
  setSelectedLevels([]);
  setSelectedRolls([]);
  setRows(initialRows);
}




  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'year', headerName: '年', width: 100, editable: true, type: 'singleSelect', valueOptions: [2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022,2023,2024,2025,2026,2027,2028] },
    { field: 'month', headerName: '月', width: 100, editable: true ,type: 'singleSelect', valueOptions: [1,2,3,4,5,6,7,8,9,10,11,12] },
    { field: 'level', headerName: '级别', width: 100, editable: true ,type: 'singleSelect', valueOptions: [4,6]},
    { field: 'paper', headerName: '卷', width: 100, editable: true },
    { field: 'question_number', headerName: '题号',width: 100, editable: true},
    { field: 'question_type',headerName: '类型',width: 100, editable: true},
    { field: 'accuracyRate', headerName: '全站正确率(%)', width: 150, editable: true, type: 'number'},
    { 
      field: 'question_detail', 
      headerName: '题目详情', 
      width: 100, 
      editable: false,
      renderCell: (params) => {
        const id = params.row.id;
        return <Button onClick={() => handleDetailClick(id)}>查看</Button>;
      }
    },
    {
      field: '修改/删除',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key={`save-${id}`}
              label="Save"
              sx={{ color: 'primary.main' }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              key={`cancel-${id}`}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key={`edit-${id}`}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key={`delete-${id}`}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          选词填空题目管理
        </Typography>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
          添加新的选词填空题目
        </Button>
      </Toolbar>
     

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, height: 50}}>
  <Box sx={{ ml: 8, width: 100 }}>
    <Button color="primary" startIcon={<FilterAltRoundedIcon />} onClick={handleOpenYearDialog}>
      年份
    </Button>
  </Box>
  <Box sx={{ width: 100 }}>
    <Button color="primary" startIcon={<FilterAltRoundedIcon />} onClick={handleOpenMonthDialog}>
      月份
    </Button>
  </Box>
  <Box sx={{ width: 100 }}>
    <Button color="primary" startIcon={<FilterAltRoundedIcon />} onClick={handleOpenLevelDialog}>
      等级
    </Button>
  </Box>
  <Box sx={{ width: 100 }}>
    <Button color="primary" startIcon={<FilterAltRoundedIcon />} onClick={handleOpenRollDialog}>
      卷
    </Button>
  </Box>
  <Box sx={{ flexGrow: 1 }} />
  <Box sx={{ width: 220, display: 'flex', justifyContent: 'flex-end' }}>
    <Button color="primary" startIcon={<FilterAltOffRoundedIcon />} onClick={handleCancelAllFilters}>
      取消所有过滤
    </Button>
  </Box>
</Box>


      <Paper sx={{ width: '100%', overflowX: 'auto' }}>
        <Box sx={{ minWidth: 1200, height: 500 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onRowModesModelChange={handleRowModesModelChange}
            getRowId={(row) => row.id}
          />
        </Box>
      </Paper>

      <Dialog open={handleYearFilter} onClose={handleCloseYearDialog} maxWidth="xl" fullWidth>
        <DialogTitle>选择你需要的年份</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {Array.from(new Set(initialRows.map((row) => row.year))).map((year) => (
              <Button
                key={year}
                variant={selectedYears.includes(year) ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleYearClick(year)}
                sx={{ margin: 1 }}
              >
                {year}
              </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseYearDialog} color="secondary">
            取消
          </Button>
          <Button onClick={handleFilterYearApply} color="primary">
            确认
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={handleMonthFilter} onClose={handleCloseMonthDialog} maxWidth="xl" fullWidth>
        <DialogTitle>选择你需要的月份</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {Array.from(new Set(initialRows.map((row) => row.month))).map((month) => (
              <Button
                key={month}
                variant={selectedMonths.includes(month) ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleMonthClick(month)}
                sx={{ margin: 1 }}
              >
                {month}
              </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMonthDialog} color="secondary">
            取消
          </Button>
          <Button onClick={handleFilterMonthApply} color="primary">
            确认
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open = {handleLevelFilter} onClose = {handleCloseLevelDialog} maxWidth = "xl" fullWidth>
        <DialogTitle> 选择你需要的级别 </DialogTitle>
        <DialogContent> 
          <Box sx = {{display: 'flex', flexWrap : 'wrap'}}>
            {Array.from(new Set(initialRows.map((row) => row.level))).map((level) => (
              <Button
              key={level}
              variant={selectedLevels.includes(level) ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => handleLevelClick(level)}
              sx={{ margin: 1 }}
            >
              {level}
            </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLevelDialog} color="secondary">
            取消
          </Button>
          <Button onClick={handleLevelFilterApply} color="primary">
            确认
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open = {handleRollFilter} onClose = {handleCloseRollDialog} maxWidth = "xl" fullWidth>
        <DialogTitle> 选择你需要的卷 </DialogTitle>
        <DialogContent> 
          <Box sx = {{display: 'flex', flexWrap : 'wrap'}}>
            {Array.from(new Set(initialRows.map((row) => row.paper))).map((roll) => (
              <Button
              key={roll}
              variant={selectedRolls.includes(roll) ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => handleRollClick(roll)}
              sx={{ margin: 1 }}
            >
              {roll}
            </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRollDialog} color="secondary">
            取消
          </Button>
          <Button onClick={handleFilterRollApply} color="primary">
            确认
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={questionDetailOpen} onClose={() => setQuestionDetailOpen(false)} maxWidth="xl" fullWidth>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">题目详情</Typography>
            <Button onClick={() => setQuestionDetailOpen(false)} color="primary" sx={{ padding: '10px 20px', fontSize: '1rem' }}>
              保存
            </Button>
          </Box>
        </DialogTitle>
        <Paper sx={{ overflowX: 'auto' }}>
          <Box sx={{ width: '100%', height: 800 }}>
            {updateQuestionGrid.map((row) => (
              <Table key={row.id}>
                <TableBody>
                  <TableRow>
                    <TableCell>题干</TableCell>
                    <TableCell>{row.question}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>选项A</TableCell>
                    <TableCell>{row.optionA}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>选项B</TableCell>
                    <TableCell>{row.optionB}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>选项C</TableCell>
                    <TableCell>{row.optionC}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>选项D</TableCell>
                    <TableCell>{row.optionD}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>是否多选</TableCell>
                    <TableCell>{row.isMultipleChoice ? '是' : '否'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>类型</TableCell>
                    <TableCell>{row.type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>题型</TableCell>
                    <TableCell>{row.questionType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>文章</TableCell>
                    <TableCell>
                      <ReactQuill value={article} onChange={setArticle} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>答案</TableCell>
                    <TableCell>{row.answer}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>解析</TableCell>
                    <TableCell>
                      <ReactQuill value={explanation} onChange={setExplanation} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>易错项</TableCell>
                    <TableCell>{row.commonMistake}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </Box>
        </Paper>
        <DialogActions>
          <Button onClick={() => setQuestionDetailOpen(false)} color="primary">
            关闭
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>确认删除</DialogTitle>
        <DialogContent>
          <DialogContentText>你确定要删除这个题目吗？此操作无法撤销。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>取消</Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReadingA;

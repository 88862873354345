import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import SetQuesiton from './SetQuestionManage';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridRowProps,
} from '@mui/x-data-grid';
import { Toolbar, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Paper, DialogContentText, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Role } from '../../../interface/Types';
import { useAuth } from '../../../hooks/useAuth';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';

const initialRows: GridRowsProp = [
  { id: 1, year: 2021, month: 12, level: 4, paper: 2, question_number: 2, question_type: "阅读理解",accuracyRate: '60' },
  { id: 2, year: 2021, month: 12, level: 4, paper: 3, question_number: 3, question_type: "仔细阅读",accuracyRate: '60' },
  { id: 3, year: 2016, month: 12, level: 4, paper: 1, question_number: 2, question_type: "仔细阅读",accuracyRate: '34' },
  { id: 4, year: 2019, month: 11, level: 4, paper: 2, question_number: 2, question_type: "阅读填空",accuracyRate: '34' },
  { id: 5, year: 2039, month: 2, level: 5, paper: 3,question_number: 1, question_type: "阅读填空", accuracyRate: '34' },
  { id: 6, year: 2021, month: 11, level: 4, paper: 2, question_number: 2, question_type: "阅读理解",accuracyRate: '60' },
];



const initialSetofQuestion: GridRowsProp = [
  { Setid: 1, id: 1, type: '阅读', averagescore: 132902 },
  { Setid: 1, id: 2, type: '写作', averagescore: 54 },
  { Setid: 1, id: 3, type: '听力', averagescore: 34 },
  { Setid: 1, id: 4, type: '翻译', averagescore: 95 },
  { Setid: 2, id: 5, type: '阅读', averagescore: 40 },
  { Setid: 2, id: 6, type: '写作', averagescore: 54 },
  { Setid: 2, id: 7, type: '听力', averagescore: 34 },
  { Setid: 2, id: 8, type: '翻译', averagescore: 95 },
];






const RealTestManagement: React.FC = () => {
  const [rows, setRows] = React.useState(initialRows);
  const [SetQuestions, setSetQuestions] = React.useState(initialSetofQuestion);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [open, setOpen] = React.useState(false);
  const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<GridRowId | null>(null);
  const [updateSetQuestionGrid, setUpdateSetQuestionGrid] = React.useState<GridRowsProp>([]);
  const { user } = useAuth();

  const [handleYearFilter, sethandleYearFilter] = React.useState(false);
  const [selectedYears, setSelectedYears] = React.useState<number[]>([]);
  const [handleMonthFilter, sethandleMonthFilter] = React.useState(false);
  const [selectedMonths, setSelectedMonths] = React.useState<number[]>([]);
  const [handleLevelFilter, sethandleLevelFilter] = React.useState(false);
  const [selectedLevels, setSelectedLevels] = React.useState<number[]>([]);
  const [handleRollFilter, sethandleRollFilter] = React.useState(false);
  const [selectedRolls, setSelectedRolls] = React.useState<number[]>([]);
  const [handlSetQuestionopen, sethandlSetQuestionopen] = React.useState(false);

  

  /* 从这里开始是对DATAGRID的增删查改的所有操作 */
  const handleAlert = (id: GridRowId) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = (confirmed: boolean) => {
    if (confirmed && selectedId !== null) {
      setRows(rows.filter((row) => row.id !== selectedId));
    }
    setOpen(false);
    setSelectedId(null);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to edit.');
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to save changes.');
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to delete.');
      return;
    }
    handleAlert(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleAddClick = () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to add.');
      return;
    }
    const id = rows.length ? rows[rows.length - 1].id + 1 : 1;
    setRows([...rows, { id, year: 0, month: 0, level: 0, paper: 0, question_number: '',question_type: '',question: '', optionA: '', optionB: '', optionC: '', optionD: '', optionE: '', isMultipleChoice: false, type: '', questionType: '', answer: '', explanation: '', commonMistake: '', accuracyRate: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'RoleName' },
    }));
  };





  const navigate = useNavigate();

  const handleDetailSetQuestionClick = (id: number) => {
    let filteredQuestion = initialSetofQuestion.filter((question) => question.Setid === id);
    if (filteredQuestion.length === 0) {
      filteredQuestion = [{
        id: id,
        type: '',
        averagescore: 0
      }];
    }
    setUpdateSetQuestionGrid(filteredQuestion);
    navigate('/setQuestion', {
      state: {
        initialRows: filteredQuestion,
        rowModesModel: rowModesModel,
      }
    });
  };

//----------到这里结束对DATAGRID的操作--------------------------------------------------------------------------------

/* 从这里开始是对过滤年份的操作 */

  const handleOpenYearDialog = () => {
    sethandleYearFilter(true);
  };

  const handleCloseYearDialog = () => {
    sethandleYearFilter(false);
  }
  const handleYearClick = (year: number) => {
    setSelectedYears((prev) =>
      prev.includes(year) ? prev.filter((y) => y !== year) : [...prev, year]
    );
  };

  const handleFilterYearApply = () => {
    sethandleYearFilter(false);
    if (selectedYears.length > 0) {
      const filteredRows = initialRows.filter((row) => selectedYears.includes(row.year));
      setRows(filteredRows);
    } else {
      setRows(initialRows);
    }
  };
//----------到这里结束--------------------------------------------------------------------------------


/* 从这里开始是对过滤月份的操作 */
  const handleOpenMonthDialog = () => {
    sethandleMonthFilter(true);
  };
  const handleCloseMonthDialog = () => {
    sethandleMonthFilter(false);
  };

  const handleMonthClick = (month: number) => {
    setSelectedMonths((prev) =>
      prev.includes(month) ? prev.filter((y) => y !== month) : [...prev, month]
    );
  };

  const handleFilterMonthApply = () => {
    sethandleMonthFilter(false);
    if (selectedMonths.length > 0) {
      const filteredRows = initialRows.filter((row) => selectedMonths.includes(row.month));
      setRows(filteredRows);
    } else {
      setRows(initialRows);
    }
  };

//----------到这里结束对月份过滤的操作--------------------------------------------------------------------------------


/* 从这里开始是对过滤级别的操作 */

const handleOpenLevelDialog = () => {
  sethandleLevelFilter(true);
};

const handleCloseLevelDialog = () => {
  sethandleLevelFilter(false);
};

const handleLevelFilterApply = () => {
  sethandleLevelFilter(false)
  if(selectedLevels.length > 0){
    const filteredRows = initialRows.filter((row) => selectedLevels.includes(row.level))
    setRows(filteredRows)
  }
  else{
    setRows(initialRows)
  }
};

const handleLevelClick = (level: number) => {
  setSelectedLevels((pre) => 
    pre.includes(level) ? pre.filter((l) => l !== level) : [...pre, level]
  ) 
}

//----------到这里结束对级别过滤的操作--------------------------------------------------------------------------------


/* 从这里开始是对过滤卷的操作 */
const handleOpenRollDialog = () => {
  sethandleRollFilter(true);
};

const handleCloseRollDialog = () => {
  sethandleRollFilter(false);
};

const handleRollClick = (roll: number) => {
  setSelectedRolls((prev) =>
    prev.includes(roll) ? prev.filter((y) => y !== roll) : [...prev, roll]
  );
};

const handleFilterRollApply = () => {
  sethandleRollFilter(false);
  if (selectedRolls.length > 0) {
    const filteredRows = initialRows.filter((row) => selectedRolls.includes(row.paper));
    setRows(filteredRows);
  } else {
    setRows(initialRows);
  }
};
//----------到这里结束对卷过滤的操作--------------------------------------------------------------------------------



const handleCancelAllFilters = () => {
    setSelectedYears([]);
    setSelectedMonths([]);
    setSelectedLevels([]);
    setSelectedRolls([]);
    setRows(initialRows);
}



  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'year', headerName: '年', width: 100, editable: true, type: 'singleSelect', valueOptions: [2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022,2023,2024,2025,2026,2027,2028] },
    { field: 'month', headerName: '月', width: 100, editable: true ,type: 'singleSelect', valueOptions: [1,2,3,4,5,6,7,8,9,10,11,12] },
    { field: 'level', headerName: '级别', width: 100, editable: true ,type: 'singleSelect', valueOptions: [4,6]},
    { field: 'paper', headerName: '卷', width: 100, editable: true },
    { field: 'accuracyRate', headerName: '全站得分', width: 150, editable: true, type: 'number'},
    { 
      field: 'question_detail', 
      headerName: '题目详情', 
      width: 100, 
      editable: false,
      renderCell: (params) => {
        const id = params.row.id;
        return <strong><Button 
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }} onClick={() => handleDetailSetQuestionClick(id)}>详情</Button> </strong> 
      }
    },
    {
      field: '修改/删除',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key={`save-${id}`}
              label="Save"
              sx={{ color: 'primary.main' }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              key={`cancel-${id}`}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key={`edit-${id}`}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key={`delete-${id}`}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          真题管理
        </Typography>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
          添加新的真题套题
        </Button>
      </Toolbar>
     

<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, height: 50}}>
  <Box sx={{ ml: 8, width: 100 }}>
    <Button color="primary" startIcon={<FilterAltRoundedIcon />} onClick={handleOpenYearDialog}>
      年份
    </Button>
  </Box>
  <Box sx={{ width: 100 }}>
    <Button color="primary" startIcon={<FilterAltRoundedIcon />} onClick={handleOpenMonthDialog}>
      月份
    </Button>
  </Box>
  <Box sx={{ width: 100 }}>
    <Button color="primary" startIcon={<FilterAltRoundedIcon />} onClick={handleOpenLevelDialog}>
      等级
    </Button>
  </Box>
  <Box sx={{ width: 100 }}>
    <Button color="primary" startIcon={<FilterAltRoundedIcon />} onClick={handleOpenRollDialog}>
      卷
    </Button>
  </Box>
  <Box sx={{ flexGrow: 1 }} />
  <Box sx={{ width: 220, display: 'flex', justifyContent: 'flex-end' }}>
    <Button color="primary" startIcon={<FilterAltOffRoundedIcon />} onClick={handleCancelAllFilters}>
      取消所有过滤
    </Button>
  </Box>
</Box>


      <Paper sx={{ width: '100%', overflowX: 'auto' }}>
        <Box sx={{ minWidth: 1200, height: 500 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onRowModesModelChange={handleRowModesModelChange}
            getRowId={(row) => row.id}
          />
        </Box>
      </Paper>

      <Dialog open={handleYearFilter} onClose={handleCloseYearDialog} maxWidth="xl" fullWidth>
        <DialogTitle>选择你需要的年份</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {Array.from(new Set(rows.map((row) => row.year))).map((year) => (
              <Button
                key={year}
                variant={selectedYears.includes(year) ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleYearClick(year)}
                sx={{ margin: 1 }}
              >
                {year}
              </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseYearDialog} color="secondary">
            取消
          </Button>
          <Button onClick={handleFilterYearApply} color="primary">
            确认
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={handleMonthFilter} onClose={handleCloseMonthDialog} maxWidth="xl" fullWidth>
        <DialogTitle>选择你需要的月份</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {Array.from(new Set(rows.map((row) => row.month))).map((month) => (
              <Button
                key={month}
                variant={selectedMonths.includes(month) ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleMonthClick(month)}
                sx={{ margin: 1 }}
              >
                {month}
              </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMonthDialog} color="secondary">
            取消
          </Button>
          <Button onClick={handleFilterMonthApply} color="primary">
            确认
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open = {handleLevelFilter} onClose = {handleCloseLevelDialog} maxWidth = "xl" fullWidth>
        <DialogTitle> 选择你需要的级别 </DialogTitle>
        <DialogContent> 
          <Box sx = {{display: 'flex', flexWrap : 'wrap'}}>
            {Array.from(new Set(rows.map((row) => row.level))).map((level) => (
              <Button
              key={level}
              variant={selectedLevels.includes(level) ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => handleLevelClick(level)}
              sx={{ margin: 1 }}
            >
              {level}
            </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLevelDialog} color="secondary">
            取消
          </Button>
          <Button onClick={handleLevelFilterApply} color="primary">
            确认
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open = {handleRollFilter} onClose = {handleCloseRollDialog} maxWidth = "xl" fullWidth>
        <DialogTitle> 选择你需要的卷 </DialogTitle>
        <DialogContent> 
          <Box sx = {{display: 'flex', flexWrap : 'wrap'}}>
            {Array.from(new Set(rows.map((row) => row.paper))).map((roll) => (
              <Button
              key={roll}
              variant={selectedRolls.includes(roll) ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => handleRollClick(roll)}
              sx={{ margin: 1 }}
            >
              {roll}
            </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRollDialog} color="secondary">
            取消
          </Button>
          <Button onClick={handleFilterRollApply} color="primary">
            确认
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>确认删除</DialogTitle>
        <DialogContent>
          <DialogContentText>你确定要删除这个题目吗？此操作无法撤销。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>取消</Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RealTestManagement;

/**
 * 注意事项：
 * 1. Question数据中，content和correct在一开始是JSON object，但是后端进行修改后变成了JSON string。为了数据结构的准确，在fetchData中，content和correct都转化成了JSON object格式。
 * 2. 在handleFormSave中，会将content和correct转换成JSON string的格式，传给后端。
 * 3. 目前截至8/23号，Question的后端数据中，name, level level_name均为自动生成的默认值：CET，6 & CET6。因此在create和update时，该三个field没有被传入。
 */

import React, { useEffect, useState, useCallback } from 'react';
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { Button, IconButton, Paper, Typography, Box } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAuth } from '../../hooks/useAuth';
import { Question, QuestionContent, QuestionCorrect } from '../../interface/QuestionTypes';
import FilterDialog from '../../components/FilterDialog';
import QuestionContentDialog from './ContentDialog';
import CorrectDialog from './CorrectDialog';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import QuestionFormDialog from './QuestionFormDialog';

// Environment variables for API configuration
const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;
const QUESTIONS_ENDPOINT = process.env.REACT_APP_QUESTIONS_ENDPOINT;

const QuestionPage = () => {
  // State variables
  const [questions, setQuestions] = useState<Question[]>([]);
  const { user } = useAuth();
  const [filterOpen, setFilterOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    id: true,
    created_at: false,
    updated_at: false,
    question_type: true,
    short_title: true,
    score: true,
    grade_level: true,
    difficult: true,
    vip_level: true,
    content: true,
    correct: true,
    status: true,
    subject_id: false,
    name: false,
    level: false,
    level_name: false,
    actions: true,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'asc' },
  ]);

  // Dialog state variables
  const [contentOpen, setContentOpen] = useState(false);
  const [correctOpen, setCorrectOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState<QuestionContent | null>(null);
  const [selectedCorrect, setSelectedCorrect] = useState<QuestionCorrect | null>(null);
  const [selectedQuestionType, setSelectedQuestionType] = useState<string | null>(null);
  const [formOpen, setFormOpen] = useState(false);
  const [formMode, setFormMode] = useState<'edit' | 'add'>('add');
  const [formInitialData, setFormInitialData] = useState<Question | null>(null);

  // The origin quesiton type in the raw response
  interface RawQuestion {
    id: number;
    created_at: string;
    updated_at: string;
    delete_at: string;
    question_type: string;
    short_title: string;
    score: number;
    grade_level: number;
    difficult: number;
    vip_level: number;
    content: string; // content is a JSON string in the raw response
    correct: string; // correct is a JSON string in the raw response
    status: string;
    subject_id: number;
    name: string;
    level: number;
    level_name: string;
  }

  // Fetch question data from the API when the component mounts or when the user changes
  const fetchQuestion = useCallback(async () => {
    try {
      if (user) {
        const response = await fetch(`${BASE_URL}/${API_VERSION}/${QUESTIONS_ENDPOINT}`, {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${user.access_token}`,
          },
        });

        if (response.ok) {
          const rawData: RawQuestion[] = await response.json();
          const data: Question[] = rawData.map((item: RawQuestion) => ({
            ...item,
            content: JSON.parse(item.content),
            correct: JSON.parse(item.correct),
          }));
          setQuestions(data);
        } else {
          console.error('Failed to fetch questions:', response.statusText);
          throw new Error(`Failed to fetch question: ${response.statusText}`);
        }
      }
    } catch (error) {
      console.error('Error fetching question:', error);
      alert(`Error fetching question: ${error}`);
    }
  }, [user]);


  useEffect(() => {
    fetchQuestion();
  }, [fetchQuestion]);

  // Handler to open the content dialog
  const handleContentOpen = (content: QuestionContent) => {
    setSelectedContent(content);
    setContentOpen(true);
  };

  // Handler to close the content dialog
  const handleContentClose = () => {
    setContentOpen(false);
    setSelectedContent(null);
  };

  // Handler to open the correct answer dialog
  const handleCorrectOpen = (content: QuestionContent, correct: QuestionCorrect, questionType: string) => {
    setSelectedContent(content);
    setSelectedCorrect(correct);
    setSelectedQuestionType(questionType);
    setCorrectOpen(true);
  };

  // Handler to close the correct answer dialog
  const handleCorrectClose = () => {
    setCorrectOpen(false);
    setSelectedContent(null);
    setSelectedCorrect(null);
    setSelectedQuestionType(null);
  };

  // Handler to change the sorting model of the data grid
  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  // Handler to open the question form dialog in either 'edit' or 'add' mode
  const handleDialogOpen = (mode: 'edit' | 'add', data?: Question) => {
    setFormMode(mode);
    setFormInitialData(data || null);
    setFormOpen(true);
  };

  // Handler to close the question form dialog
  const handleFormClose = () => {
    setFormOpen(false);
    setFormInitialData(null);
  };

  // Handler to save the question form dialog
  const handleFormSave = async (question: Question) => {
    if (user) {
      try {
        // Define all the fields in request body
        const {
          question_type,
          short_title,
          score,
          grade_level,
          difficult,
          vip_level,
          content,
          correct,
          status,
          subject_id,
        } = question;

        const requestBody = {
          question_type,
          short_title,
          score,
          grade_level,
          difficult,
          vip_level,
          content: JSON.stringify(content),
          correct: JSON.stringify(correct),
          status,
          subject_id,
        };

        let response = new Response;
        if (formMode === 'add') {
          response = await fetch(`${BASE_URL}/${API_VERSION}/${QUESTIONS_ENDPOINT}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.access_token}`,
            },
            body: JSON.stringify(requestBody),
          });
        } else if (formMode === 'edit') {
          response = await fetch(`${BASE_URL}/${API_VERSION}/${QUESTIONS_ENDPOINT}/${question.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.access_token}`,
            },
            body: JSON.stringify(requestBody),
          });
        }

        if (!response.ok) {
          const responseBody = await response.json();
          console.error(`Failed to ${formMode === 'add' ? 'create' : 'update'} question:`, responseBody);
          throw new Error(`Failed to ${formMode === 'add' ? 'create' : 'update'} question: ${response.statusText}`);
        }

        // After successful save, refetch the questions to update the table
        await fetchQuestion();

        setFormOpen(false);
        setFormInitialData(null);

      } catch (error) {
        console.error('Error saving question:', error);
        alert(`Error saving question: ${error}`);
      }
    }
  };

  // Handler to delete a question by its ID
  const handleDeleteClick = async (id: GridRowId) => {
    if (user) {
      try {
        const response = await fetch(`${BASE_URL}/${API_VERSION}/${QUESTIONS_ENDPOINT}/${id}`, {
          method: 'DELETE',
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${user.access_token}`,
          },
        });

        if (response.ok) {
          await fetchQuestion(); // Refresh the data after deletion
        } else {
          console.error('Failed to delete question:', response.statusText);
          alert(`Error deleting question: ${response.statusText}`);
        }
      } catch (error) {
        console.error('An unexpected error occurred:', error);
        alert('An unexpected error occurred while deleting the question. Please try again later.');
      }
    }
  };

  // Define the columns for the data grid
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'created_at', headerName: 'Created At', width: 150 },
    { field: 'updated_at', headerName: 'Updated At', width: 150 },
    { field: 'question_type', headerName: 'Type', width: 150 },
    { field: 'short_title', headerName: 'Short Title', width: 150 },
    { field: 'score', headerName: 'Score', width: 110 },
    { field: 'grade_level', headerName: 'Grade Level', width: 130 },
    { field: 'difficult', headerName: 'Difficult', width: 130 },
    { field: 'vip_level', headerName: 'VIP Level', width: 130 },
    {
      field: 'content',
      headerName: 'Content',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleContentOpen(params.row.content)}
        >
          View Content
        </Button>
      ),
    },
    {
      field: 'correct',
      headerName: 'Correct',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCorrectOpen(params.row.content, params.row.correct, params.row.question_type)}
        >
          View Answer
        </Button>
      ),
    },
    { field: 'status', headerName: 'Status', width: 130 },
    { field: 'subject_id', headerName: 'Subject ID', width: 130 },
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'level', headerName: 'Level', width: 130 },
    { field: 'level_name', headerName: 'Level name', width: 130 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          key={`edit-${id}`}
          label="Edit"
          className="textPrimary"
          onClick={() => handleDialogOpen('edit', questions.find((row) => row.id === id))}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          key={`delete-${id}`}
          label="Delete"
          onClick={() => handleDeleteClick(id)}
          color="inherit"
        />,
      ],
    },
  ];

  // Filter the columns based on user visibility preferences
  const visibleColumnsFiltered = columns.filter((column) => visibleColumns[column.field as keyof typeof visibleColumns]);

  return (
    <>
      {/* Header with title and add button */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h6" component="div">
          Question
        </Typography>
        <Button color="primary" startIcon={<AddIcon />} onClick={() => handleDialogOpen('add')}>
          Add New Question
        </Button>
      </Box>
      {/* Filter icon button */}
      <IconButton onClick={() => setFilterOpen(true)}>
        <FilterListIcon />
      </IconButton>
      {/* Filter dialog */}
      <FilterDialog
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        visibleColumns={visibleColumns}
        handleFilterChange={(e) => setVisibleColumns({ ...visibleColumns, [e.target.name]: e.target.checked })}
      />
      {/* Data grid */}
      <Paper style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={questions}
          columns={visibleColumnsFiltered}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </Paper>
      {/* Content dialog */}
      <QuestionContentDialog open={contentOpen} onClose={handleContentClose} content={selectedContent} />
      {/* Correct answer dialog */}
      <CorrectDialog
        open={correctOpen}
        onClose={handleCorrectClose}
        correct={selectedCorrect}
        questionType={selectedQuestionType}
      />
      {/* Question form dialog */}
      <QuestionFormDialog
        open={formOpen}
        onClose={handleFormClose}
        onSave={handleFormSave}
        initialData={formInitialData}
        mode={formMode}
      />
    </>
  );
};

export default QuestionPage;
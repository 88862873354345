import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { QuestionContent, SingleChoiceContent, MultipleChoiceContent, TrueFalseContent, GapFillingContent, ShortAnswerContent, WordCardContent } from '../../interface/QuestionTypes';

interface QuestionContentDialogProps {
  open: boolean; // Controls whether the dialog is open or closed
  onClose: () => void; // Function to close the dialog
  content: QuestionContent | null; // The content of the question to display in the dialog
}

const QuestionContentDialog: React.FC<QuestionContentDialogProps> = ({ open, onClose, content }) => {
  
  // Function to render the content details based on the question type
  const renderContentDetails = (content: QuestionContent) => {
    // Handle Single Choice question type
    if ('single_choice' in content) {
      const singleChoice = content.single_choice as SingleChoiceContent;
      return (
        <>
          <TextField 
            label="Question" 
            value={singleChoice.question} 
            fullWidth 
            margin="normal" 
            InputProps={{ readOnly: true }} 
          />
          {singleChoice.options.map((option, index) => (
            <TextField 
              key={index} 
              label={`Option ${index}`} 
              value={option} 
              fullWidth 
              margin="normal" 
              InputProps={{ readOnly: true }} 
            />
          ))}
        </>
      );
    }

    // Handle Multiple Choice question type
    if ('multiple_choice' in content) {
      const multipleChoice = content.multiple_choice as MultipleChoiceContent;
      return (
        <>
          <TextField 
            label="Question" 
            value={multipleChoice.question} 
            fullWidth 
            margin="normal" 
            InputProps={{ readOnly: true }} 
          />
          {multipleChoice.options.map((option, index) => (
            <TextField 
              key={index} 
              label={`Option ${index}`} 
              value={option} 
              fullWidth 
              margin="normal" 
              InputProps={{ readOnly: true }} 
            />
          ))}
        </>
      );
    }

    // Handle True/False question type
    if ('true_false' in content) {
      const trueFalse = content.true_false as TrueFalseContent;
      return (
        <TextField 
          label="Question" 
          value={trueFalse.question} 
          fullWidth 
          margin="normal" 
          InputProps={{ readOnly: true }} 
        />
      );
    }

    // Handle Gap Filling question type
    if ('gap_filling' in content) {
      const gapFilling = content.gap_filling as GapFillingContent;
      return (
        <TextField 
          label="Question" 
          value={gapFilling.question} 
          fullWidth 
          margin="normal" 
          InputProps={{ readOnly: true }} 
        />
      );
    }

    // Handle Short Answer question type
    if ('short_answer' in content) {
      const shortAnswer = content.short_answer as ShortAnswerContent;
      return (
        <TextField 
          label="Question" 
          value={shortAnswer.question} 
          fullWidth 
          margin="normal" 
          InputProps={{ readOnly: true }} 
        />
      );
    }

    // Handle Word Card question type
    if ('word_card' in content) {
      const wordCard = content.word_card as WordCardContent;
      return (
        <>
          {wordCard.words.map((word, index) => (
            <TextField 
              key={index} 
              label={`Word ${index}`} 
              value={word} 
              fullWidth 
              margin="normal" 
              InputProps={{ readOnly: true }} 
            />
          ))}
        </>
      );
    }

    // Return null if the content does not match any known question type
    return null;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Content Details</DialogTitle>
      <DialogContent>
        {/* Only render the content details if content is provided */}
        {content && renderContentDetails(content)}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionContentDialog;

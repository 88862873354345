import React, { useState } from 'react';
import CrudGrid from '../../components/CrudGrid';
import { fetchData, deleteData, saveData } from '../../components/ParentComponent';
import { useAuth } from '../../hooks/useAuth';
import { GridColDef } from '@mui/x-data-grid';
import ErrorPopup from '../../error/ErrorPopup';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'level',
    headerName: '特权等级',
    width: 180,
    editable: true,
    type: 'singleSelect',
    valueOptions: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  },
  {
    field: 'description',
    headerName: '描述',
    width: 400,
    editable: true,
  }
];

const PrivilegePage: React.FC = () => {
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const handleError = (error: string) => {
    setErrorMessage(error);
    setShowErrorPopup(true);
  };

  return (
    <div>
      {showErrorPopup && <ErrorPopup message={errorMessage} onClose={() => setShowErrorPopup(false)} />}
      <CrudGrid
        userRole={user?.role || 'guest'}
        fetchData={() => fetchData(null, '/get_permissions', handleError)}
        deleteData={(id) => deleteData(id, '/delete_permission', handleError)}
        saveData={(updatedRow) => saveData(updatedRow, '/create_permission', '/update_permission', handleError)}
        columns={columns}
        title="特权"
      />
    </div>
  );
};

export default PrivilegePage;

import React, { useEffect, useState, useCallback } from 'react';
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { Button, IconButton, Paper, Typography, Box } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAuth } from '../hooks/useAuth';
import FilterDialog from '../components/FilterDialog';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { User } from '../interface/UserTypes';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;
const USERS_ENDPOINT = '/users';

const UserPage = () => {
  const [users, setUsers] = useState<User[]>([]);
  const { user } = useAuth();
  const [filterOpen, setFilterOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    id: true,
    created_at: false,
    updated_at: false,
    username: true,
    role: true,
    name: true,
    email: true,
    gender: true,
    school_grade: true,
    vip_level: true,
    points: true,
    actions: true,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'asc' },
  ]);

  // Memoize the fetchUsers function to avoid unnecessary re-creations
  const fetchUsers = useCallback(async () => {
    try {
      if (user) {
        const response = await fetch(`${BASE_URL}/${API_VERSION}${USERS_ENDPOINT}`, {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${user.access_token}`,
          },
        });

        if (response.ok) {
          const data: User[] = await response.json();
          setUsers(data);
        } else {
          console.error('Failed to fetch users:', response.statusText);
          throw new Error(`Failed to fetch users: ${response.statusText}`);
        }
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      alert(`Error fetching users: ${error}`);
    }
  }, [user]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  const handleDialogOpen = (mode: 'edit' | 'add', data?: User) => {
    if (mode === 'add') {
      console.log('Add New User functionality will go here.');
    } else if (mode === 'edit' && data) {
      console.log(`Edit User functionality for user with ID ${data.id} will go here.`);
    }
  };

  const handleDeleteClick = async (id: GridRowId) => {
    if (user) {
      try {
        const response = await fetch(`${BASE_URL}/${API_VERSION}${USERS_ENDPOINT}/${id}`, {
          method: 'DELETE',
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${user.access_token}`,
          },
        });

        if (response.ok) {
          await fetchUsers(); // Refresh the data after deletion
        } else {
          console.error('Failed to delete user:', response.statusText);
          alert(`Error deleting user: ${response.statusText}`);
        }
      } catch (error) {
        console.error('An unexpected error occurred:', error);
        alert('An unexpected error occurred while deleting the user. Please try again later.');
      }
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'created_at', headerName: 'Created At', width: 150 },
    { field: 'updated_at', headerName: 'Updated At', width: 150 },
    { field: 'username', headerName: 'Username', width: 150 },
    { field: 'role', headerName: 'Role', width: 130 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'gender', headerName: 'Gender', width: 120 },
    { field: 'school_grade', headerName: 'School Grade', width: 130 },
    { field: 'vip_level', headerName: 'VIP Level', width: 110 },
    { field: 'points', headerName: 'Points', width: 110 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          key={`edit-${id}`}
          label="Edit"
          className="textPrimary"
          onClick={() => handleDialogOpen('edit', users.find((row) => row.id === id))}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          key={`delete-${id}`}
          label="Delete"
          onClick={() => handleDeleteClick(id)}
          color="inherit"
        />,
      ],
    },
  ];

  const visibleColumnsFiltered = columns.filter((column) => visibleColumns[column.field as keyof typeof visibleColumns]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h6" component="div">
          Users
        </Typography>
        <Button color="primary" startIcon={<AddIcon />} onClick={() => handleDialogOpen('add')}>
          Add New User
        </Button>
      </Box>
      <IconButton onClick={() => setFilterOpen(true)}>
        <FilterListIcon />
      </IconButton>
      <FilterDialog
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        visibleColumns={visibleColumns}
        handleFilterChange={(e) => setVisibleColumns({ ...visibleColumns, [e.target.name]: e.target.checked })}
      />
      <Paper style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={users}
          columns={visibleColumnsFiltered}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </Paper>
    </>
  );
};

export default UserPage;

import { useEffect, useCallback, useState } from "react";
import { Role, User } from "../interface/Types";
import { debounce } from "lodash"

export const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);

  const setItem = useCallback((key: string, value: string) => {
    localStorage.setItem(key, value);
  }, []);

  const removeItem = useCallback((key: string) => {
    localStorage.removeItem(key);
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = useCallback(
    debounce(async (username: string, password: string) => {
      const BASE_URL = process.env.REACT_APP_BASE_URL; // Base URL for the FastAPI backend
      const response = await fetch(`${BASE_URL}/admin/v1/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "accept": "application/json",
        },
        body: new URLSearchParams({
          grant_type: 'password',
          username: username,
          password: password,
          scope: '',
          client_id: 'string',
          client_secret: 'string'
        })
      });

      const data = await response.json();
      // Login Fail
      if (!data.id) {
        console.error("Login error:", data.detail);
        const userData = null;
        setUser(userData);
        setItem("user", JSON.stringify(userData));
        setError("An error occurred");
      }
      else {
        console.log(data);
        const role = "Admin" as Role;
        console.log("Role:", role);
        const userData = { id: data.id, username: username, role: role, token_type: data.token_type, access_token: data.access_token, refresh_token: data.refresh_token };
        setUser(userData);
        setItem("user", JSON.stringify(userData));
        setError(null);
      }
    }, 300),
    [setItem]
  );

  const logout = useCallback(() => {
    setUser(null);
    removeItem("user");
  }, [removeItem]);

  return { user, login, logout, error };
};

import { TableRow } from '../interface/Types';
import { GridRowId } from '@mui/x-data-grid';

const BASE_URL = process.env.REACT_APP_BASE_URL; // Base URL for the FastAPI backend

// Get Token from local storage
const getToken = () => {
  const storedUser = localStorage.getItem('user');
  if (storedUser) {
    const user = JSON.parse(storedUser);
    return user.access_token;
  }
  return null;
};

export const fetchData = async (body: TableRow | null, getUrl: string, handleError: (error: string) => void): Promise<TableRow[]> => {
  try {
    const token = getToken();
    const options: RequestInit = {
      method: body ? 'POST' : 'GET', // Use GET if body is null, otherwise use POST
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${BASE_URL}${getUrl}`, options);

    if (!response.ok) {
      const errorData = await response.json();
      const error = errorData.detail || 'Unknown error occurred';
      throw new Error(error);
    }

    const data: TableRow[] = await response.json();
    return data;
  } catch (error) {
    if (error instanceof TypeError) {
      handleError('Network error: Could not connect to the server.');
    } else if (error instanceof Error) {
      handleError(error.message);
    } else {
      handleError('An unexpected error occurred.');
    }
    throw error;
  }
};


export const deleteData = async (id: GridRowId, deleteUrl: string, handleError: (error: string) => void) => {
  try {
    const token = getToken(); // Get Token stored by useAuth.ts
    const response = await fetch(`${BASE_URL}${deleteUrl}/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json(); // Assuming server sends JSON with an error message
      const error = errorData.detail || 'Unknown error occurred'; // Fallback error message
      throw new Error(error); // Throw to be caught in the outer catch
    }

    return await response.json(); // Assuming the response is JSON
  } catch (error) {
    if (error instanceof TypeError) {
      handleError('Network error: Could not connect to the server.');
    } else if (error instanceof Error) {
      handleError(error.message);
    } else {
      handleError('An unexpected error occurred.');
    }
    console.error(error);
  }
};

export const saveData = async (updatedRow: TableRow, createRoleUrl: string, updateRoleUrl: string, handleError: (error: string) => void) => {
  try {
    const token = getToken(); // Get Token stored by useAuth.ts
    const isNew = updatedRow.isNew;
    const url = isNew ? `${BASE_URL}${createRoleUrl}` : `${BASE_URL}${updateRoleUrl}/${updatedRow.id}`;
    const method = isNew ? 'POST' : 'PUT';
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(updatedRow),
    });

    if (!response.ok) {
      const errorData = await response.json(); // Assuming server sends JSON with an error message
      const error = errorData.detail || 'Unknown error occurred'; // Fallback error message
      throw new Error(error); // Throw to be caught in the outer catch
    }

    const data = await response.json(); // Handle successful response
    return { ...updatedRow, isNew: false, ...data };
  } catch (error: unknown) {
    let errorMessage = 'An unexpected error occurred.';
    if (error instanceof TypeError) {
      errorMessage = 'Network error: Could not connect to the server.';
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { error: errorMessage };
  }
};

import { GridRowId } from '@mui/x-data-grid';
import React from 'react';
import { GridValidRowModel } from '@mui/x-data-grid';

export enum Role {
  staff = 'staff',
  admin = 'Admin',
  guest = 'guest',
}

export enum DecisionType {
  ALLOW = 'allow',
  REDIRECT = 'redirect',
}

export interface Route {
  path: string;
  component: React.ComponentType;
  exact?: boolean;
  acl: {
    [key in Role]: {
      type: DecisionType;
      meta?: string;
    };
  };
}

export interface UpdatedRole {
  id: number;
  name: string;
  description: string;
  permission_ids: number[];
  isNew: boolean;
}

export interface UpdatedPermission {
  id: number;
  level: number;
  description: string;
  isNew: boolean;
}

export interface UpdatedMembership {
  id: number;
  admin_id: number;
  level: string;
  description: string;
  isNew: boolean;
}

export interface User {
  id: number;
  username: string;
  role: Role;
  token_type: string;
  access_token: string;
  refresh_token: string;
}

export interface TableRow extends GridValidRowModel {
  id: GridRowId;
  isNew: boolean;
}

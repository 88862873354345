import React, { useState } from 'react';
import CrudGrid from '../../components/CrudGrid';
import { fetchData, deleteData, saveData } from '../../components/ParentComponent';
import { useAuth } from '../../hooks/useAuth';
import { GridColDef } from '@mui/x-data-grid';
import ErrorPopup from '../../error/ErrorPopup'; // Updated import path

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'admin_id', headerName: '用户ID', width: 230, editable: true },
  {
    field: 'level',
    headerName: '会员等级',
    width: 230,
    editable: true,
    type: 'singleSelect',
    valueOptions: ['尊贵会员', '超凡会员', '超级会员', '会员'],
  },
  { field: 'description', headerName: '描述', width: 230, editable: true }
];

const MembershipPage: React.FC = () => {
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const handleError = (error: string) => {
    setErrorMessage(error);
    setShowErrorPopup(true);
  };

  return (
    <div>
      {showErrorPopup && <ErrorPopup message={errorMessage} onClose={() => setShowErrorPopup(false)} />}
      <CrudGrid
        userRole={user?.role || 'guest'}
        fetchData={() => fetchData(null, '/get_memberships', handleError)}
        deleteData={(id) => deleteData(id, '/delete_membership', handleError)}
        saveData={(TableRow) => saveData(TableRow, '/create_membership', '/update_membership', handleError)}
        columns={columns}
        title="会员"
      />
    </div>
  );
};

export default MembershipPage;

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, ListItemText, SelectChangeEvent, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { QuestionPaper } from '../../interface/QuestionPaperTypes';

interface QuestionPaperFormDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (questionPaper: QuestionPaper) => void;
    initialData?: QuestionPaper | null;
    mode: 'edit' | 'add';
}

// Default question paper structure to reset the form
const defaultQuestionPaper: QuestionPaper = {
    id: 0,
    created_at: '',
    updated_at: '',
    name: '',
    paper_type: 'past',
    grade_level: 0,
    score: 0,
    questions: [],
    question_count: 0,
    random: false,
    suggest_time: 0,
    limit_start_time: null,
    limit_end_time: null,
};

const QuestionPaperFormDialog: React.FC<QuestionPaperFormDialogProps> = ({ open, onClose, onSave, initialData, mode }) => {
    const [tempFormData, setTempFormData] = useState<QuestionPaper>(defaultQuestionPaper);

    useEffect(() => {
        if (initialData && mode === 'edit') {
            setTempFormData(initialData);
        } else {
            setTempFormData(defaultQuestionPaper);
        }
    }, [initialData, mode]);

    const handleTempChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        const { name, value } = e.target;
        setTempFormData({
            ...tempFormData,
            [name as keyof QuestionPaper]: value,
        });
    };

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const { name, value } = event.target;
        setTempFormData({
            ...tempFormData,
            [name as keyof QuestionPaper]: value,
        });
    };

    const handleQuestionChange = (index: number, value: string) => {
        const updatedQuestions = [...tempFormData.questions];
        updatedQuestions[index] = parseInt(value) || 0;
        setTempFormData({
            ...tempFormData,
            questions: updatedQuestions,
            question_count: updatedQuestions.length,
        });
    };

    const handleQuestionCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const count = parseInt(e.target.value);
        const updatedQuestions = [...tempFormData.questions];

        if (count > updatedQuestions.length) {
            // Add empty questions if count is greater
            for (let i = updatedQuestions.length; i < count; i++) {
                updatedQuestions.push(0);
            }
        } else {
            // Remove questions if count is smaller
            updatedQuestions.splice(count);
        }

        setTempFormData({
            ...tempFormData,
            questions: updatedQuestions,
            question_count: count,
        });
    };

    const handleSave = () => {
        onSave(tempFormData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{mode === 'edit' ? 'Edit Question Paper' : 'Add New Question Paper'}</DialogTitle>
            <DialogContent dividers>
                <TextField
                    margin="dense"
                    label="Name"
                    name="name"
                    value={tempFormData.name}
                    onChange={handleTempChange}
                    fullWidth
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel>Paper Type</InputLabel>
                    <Select
                        value={tempFormData.paper_type}
                        onChange={handleSelectChange}
                        label="Paper Type"
                        name="paper_type"
                    >
                        <MenuItem value="past">Past</MenuItem>
                        <MenuItem value="module">Module</MenuItem>
                        <MenuItem value="fragment">Fragment</MenuItem>
                        <MenuItem value="task">Task</MenuItem>
                        <MenuItem value="plan">Plan</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    margin="dense"
                    label="Grade Level"
                    name="grade_level"
                    type="number"
                    value={tempFormData.grade_level}
                    onChange={handleTempChange}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    label="Score"
                    name="score"
                    type="number"
                    value={tempFormData.score}
                    onChange={handleTempChange}
                    fullWidth
                />

                {/* Question Count and List */}
                <TextField
                    margin="dense"
                    label="Question Count"
                    name="question_count"
                    type="number"
                    value={tempFormData.question_count}
                    onChange={handleQuestionCountChange}
                    fullWidth
                />
                {tempFormData.questions.map((id, index) => (
                    <TextField
                        key={index}
                        margin="dense"
                        label={`Question ${index + 1} ID`}
                        value={id}
                        onChange={(e) => handleQuestionChange(index, e.target.value)}
                        type="number"
                        fullWidth
                    />
                ))}

                {/* Random Select */}
                <FormControl fullWidth margin="dense">
                    <InputLabel>Random Selection</InputLabel>
                    <Select
                        value={tempFormData.random ? 'true' : 'false'}
                        onChange={(e) => setTempFormData({ ...tempFormData, random: e.target.value === 'true' })}
                        label="Random Selection"
                        name="random"
                    >
                        <MenuItem value="true">true</MenuItem>
                        <MenuItem value="false">false</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    margin="dense"
                    label="Suggested Time"
                    name="suggest_time"
                    type="number"
                    value={tempFormData.suggest_time}
                    onChange={handleTempChange}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    label="Limit Start Time"
                    name="limit_start_time"
                    type="datetime-local"
                    value={tempFormData.limit_start_time || ''}
                    onChange={handleTempChange}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="dense"
                    label="Limit End Time"
                    name="limit_end_time"
                    type="datetime-local"
                    value={tempFormData.limit_end_time || ''}
                    onChange={handleTempChange}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default QuestionPaperFormDialog;

import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

interface FilterDialogProps {
  open: boolean;
  onClose: () => void;
  visibleColumns: { [key: string]: boolean };
  handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterDialog: React.FC<FilterDialogProps> = ({ open, onClose, visibleColumns, handleFilterChange }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Filter Columns</DialogTitle>
      <DialogContent>
        <FormGroup>
          {Object.keys(visibleColumns).map((column) => (
            <FormControlLabel
              key={column}
              control={<Checkbox checked={visibleColumns[column]} onChange={handleFilterChange} name={column} />}
              label={column}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;

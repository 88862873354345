import React from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';

interface ErrorPopupProps {
    message: string;
    onClose: () => void;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ message, onClose }) => {
    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        // Add logic here if you want to handle the link click
        console.log('Link clicked');
    };

    return ReactDOM.createPortal(
        <Draggable>
            <div style={{
                position: 'fixed',
                top: '8%',
                left: '30%',
                zIndex: 1000,
                backgroundColor: '#d32f2f',
                padding: '20px',
                borderRadius: '8px',
                color: 'white',
                width: '80%',
                maxWidth: '600px',
                fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
                userSelect: 'none',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', flex: 1, marginRight: '20px' }}>
                    <div style={{
                        fontSize: '24px',
                        marginRight: '20px'
                    }}>
                        &#9888;
                    </div>
                    <div style={{ flex: 1 }}>
                        <strong>Error!</strong> {message}<br />
                        <a href="/" onClick={handleLinkClick} style={{ color: '#FFD700' }}>Open this link</a> for more information.
                    </div>
                </div>
                <div style={{
                    cursor: 'pointer',
                    fontSize: '24px',
                    marginLeft: '20px'
                }} onClick={onClose}>
                    &#x2715;
                </div>
            </div>
        </Draggable>,
        document.body
    );
};

export default ErrorPopup;


import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { Toolbar, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material';
import { Role } from '../../interface/Types';
import { useAuth } from '../../hooks/useAuth';

const initialRows: GridRowsProp = [
  {
    id: 1,
    word: 'absorb',
    part: '阅读',
    highFrequencyMeaning: '1. v. 吸收；吸引......的注意，是全神贯注；经受住；承受住',
    frequency: '近7年来考试出现31次',
    example: '1. It could one day leave us with forests that emit more carbon than they absorb. (2019年英语二阅读理解text2) 2. Some people are absorbed in the past but not open to new memories. (2017年 6月 英语四级 卷三 长篇阅读)',
    listening: '1. adj. 学术的，学习好的 2. 高校教师，学者',
    writing: '',
  },
];

const Vocabulary: React.FC = () => {
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<GridRowId | null>(null);
  const { user } = useAuth();

  const handleAlert = (id: GridRowId) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = (confirmed: boolean) => {
    if (confirmed && selectedId !== null) {
      setRows(rows.filter((row) => row.id !== selectedId));
    }
    setOpen(false);
    setSelectedId(null);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to edit.');
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to save changes.');
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to delete.');
      return;
    }
    handleAlert(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleAddClick = () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to add.');
      return;
    }
    const id = rows.length ? rows[rows.length - 1].id + 1 : 1;
    setRows([...rows, { id, word: '', part: '', highFrequencyMeaning: '', frequency: '', example: '', listening: '', writing: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'word' },
    }));
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'word', headerName: '单词', width: 150, editable: true },
    { field: 'part', headerName: '部分', width: 100, editable: true , type: 'singleSelect', valueOptions: ['阅读', '听力', '写作']},
    { field: 'highFrequencyMeaning', headerName: '高频考义', width: 300, editable: true },
    { field: 'frequency', headerName: '频率', width: 200, editable: true },
    { field: 'example', headerName: '例句. (来源）', width: 400, editable: true },
    { field: 'listening', headerName: '听力', width: 400, editable: true },
    { field: 'writing', headerName: '写作', width: 400, editable: true },
    {
      field: '修改/删除',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key={`save-${id}`}
              label="Save"
              sx={{ color: 'primary.main' }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              key={`cancel-${id}`}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key={`edit-${id}`}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key={`delete-${id}`}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          单词管理
        </Typography>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
          添加新单词
        </Button>
      </Toolbar>
      <Paper sx={{ width: '100%', overflowX: 'auto' }}>
        <Box sx={{ minWidth: 1200, height: 500 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onRowModesModelChange={handleRowModesModelChange}
            getRowId={(row) => row.id}
          />
        </Box>
      </Paper>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>确认删除</DialogTitle>
        <DialogContent>
          <DialogContentText>你确定要删除这个单词吗？此操作无法撤销。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>取消</Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Vocabulary;

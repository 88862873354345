import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import { Question } from '../../interface/QuestionTypes';

interface QuestionDetailDialogProps {
    open: boolean;
    onClose: () => void;
    question: Question | undefined;
}

const QuestionDetailDialog: React.FC<QuestionDetailDialogProps> = ({ open, onClose, question }) => {
    if (!question) return null;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Question Details</DialogTitle>
            <DialogContent>
                <Typography variant="h6" gutterBottom>
                    Question ID: {question.id}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Type:</strong> {question.question_type}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Short Title:</strong> {question.short_title}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Score:</strong> {question.score}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Grade Level:</strong> {question.grade_level}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Difficulty:</strong> {question.difficult}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>VIP Level:</strong> {question.vip_level}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Status:</strong> {question.status}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Created At:</strong> {new Date(question.created_at).toLocaleString()}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Updated At:</strong> {new Date(question.updated_at).toLocaleString()}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Content:</strong>
                </Typography>
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    {JSON.stringify(question.content, null, 2)}
                </pre>
                <Typography variant="body1" gutterBottom>
                    <strong>Correct:</strong>
                </Typography>
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    {JSON.stringify(question.correct, null, 2)}
                </pre>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default QuestionDetailDialog;

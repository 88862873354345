import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { Role, DecisionType, Route as AppRoute } from '../../interface/Types';


interface AccessControlProps {
  route: AppRoute;
  children: React.ReactNode;
}

const AccessControl: React.FC<AccessControlProps> = ({ route, children }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const role =( user ? user.role : Role.guest )as Role;
  const accessDecision = route.acl[role];

  if (accessDecision.type === DecisionType.ALLOW) {
    return <>{children}</>;
  } else if (accessDecision.type === DecisionType.REDIRECT) {
    navigate(accessDecision.meta || '/login');
    return null
  }
  return null;
};

export default AccessControl;

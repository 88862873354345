import React, { useState, useEffect, ReactNode } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import EventIcon from '@mui/icons-material/Event';
import RecentActorsRoundedIcon from '@mui/icons-material/RecentActorsRounded';
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded';
import SettingsAccessibilityRoundedIcon from '@mui/icons-material/SettingsAccessibilityRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import CurrencyYenRoundedIcon from '@mui/icons-material/CurrencyYenRounded';
import { useAuth } from '../hooks/useAuth';
import { MdAssignmentAdd } from "react-icons/md";
import Paper from '@mui/material/Paper';
import '../App.css';

const pageTitles: { [key: string]: string } = {
  '/dashboard': 'Dashboard',
  '/assign': 'Arrangement',
  '/roles': 'Roles',
  '/privileges': 'Privileges',
  '/rolesandprivileges': 'Roles and Privileges',
  '/users': 'Users',
  '/event': 'Events',
  '/price': 'Price',
  '/membershiprelation': 'Membership Relation',
  '/Membership': 'Membership',
  '/ReadingA': 'Reading A',
  '/Question': 'Question',
};

interface LayoutProps {
  children?: ReactNode;
}

const drawerWidth = 240;
const Layout: React.FC<LayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const [pageTitle, setPageTitle] = useState<string>('Dashboard');
  const [showMemberOptions, setShowMemberOptions] = useState(false);
  const [showAssignOptions, setShowAssignOptions] = useState(false);
  const [showTestOption, setShowTestOption] = useState(false);
  const [showTestManagement, setshowTestManagement] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname;
    setPageTitle(pageTitles[currentPath] || 'Dashboard');
  }, [location]);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleAssignOptions = () => {
    setShowAssignOptions(!showAssignOptions);
  };

  const toggleMemberOptions = () => {
    setShowMemberOptions(!showMemberOptions);
  }

  const toggleTestOption = () => {
    setShowTestOption(!showTestOption);
  }

  const toggleTestManagement = () => {
    setshowTestManagement(!showTestManagement)
  }
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <AppBar position="fixed" elevation={4} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => handleNavigate('/dashboard')}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {pageTitle}
          </Typography>
          <IconButton color="inherit" onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Paper elevation={2} sx={{ width: drawerWidth, height: '100vh', position: 'fixed', zIndex: 2, overflowY: 'auto', mt: '64px' }}>
        <List component="nav">
          <ListItemButton onClick={() => handleNavigate('/dashboard')}>
            <ListItemIcon>
              <DashboardIcon className="icon" />
            </ListItemIcon>
            <ListItemText primary="主页" />
          </ListItemButton>
          <ListItemButton onClick={toggleAssignOptions}>
            <ListItemIcon>
              <RecentActorsRoundedIcon className="icon" />
            </ListItemIcon>
            <ListItemText primary="角色管理" />
          </ListItemButton>
          {showAssignOptions && (
            <Box sx={{ pl: 4 }}>
              <ListItemButton onClick={() => handleNavigate('/roles')}>
                <ListItemIcon>
                  <PersonAddIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="角色" />
              </ListItemButton>
              <ListItemButton onClick={() => handleNavigate('/privileges')}>
                <ListItemIcon>
                  <AutoGraphRoundedIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="特权" />
              </ListItemButton>
              <ListItemButton onClick={() => handleNavigate('/rolesandprivileges')}>
                <ListItemIcon>
                  <SettingsAccessibilityRoundedIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="角色特权关系表" />
              </ListItemButton>
            </Box>
          )}
          <ListItemButton onClick={() => handleNavigate('/users')}>
            <ListItemIcon>
              <SupervisorAccountOutlinedIcon className="icon" />
            </ListItemIcon>
            <ListItemText primary="用户" />
          </ListItemButton>
          <ListItemButton onClick={() => handleNavigate('/event')}>
            <ListItemIcon>
              <EventIcon className="icon" />
            </ListItemIcon>
            <ListItemText primary="活动" />
          </ListItemButton>
          <ListItemButton onClick={toggleMemberOptions}>
            <ListItemIcon>
              <StarRoundedIcon className="icon" />
            </ListItemIcon>
            <ListItemText primary="会员" />
          </ListItemButton>
          {showMemberOptions && (
            <Box sx={{ pl: 4 }}>
              <ListItemButton onClick={() => handleNavigate('/price')}>
                <ListItemIcon>
                  <CurrencyYenRoundedIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="价格" />
              </ListItemButton>
              <ListItemButton onClick={() => handleNavigate('/Membership')}>
                <ListItemIcon>
                  <SettingsAccessibilityRoundedIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="会员" />
              </ListItemButton>
              <ListItemButton onClick={() => handleNavigate('/membershiprelation')}>
                <ListItemIcon>
                  <AutoGraphRoundedIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="会员关系" />
              </ListItemButton>
            </Box>
          )}
          <ListItemButton onClick={toggleTestOption}>
            <ListItemIcon>
              <MdAssignmentAdd className="icon" />
            </ListItemIcon>
            <ListItemText primary="碎片化题目管理" />
          </ListItemButton>
          {showTestOption && (
            <Box sx={{ pl: 4 }}>
              <ListItemButton onClick={() => handleNavigate('/ReadingA')}>
                <ListItemIcon>
                  <CurrencyYenRoundedIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="阅读" />
              </ListItemButton>
              <ListItemButton onClick={() => handleNavigate('/ReadingB')}>
                <ListItemIcon>
                  <AutoGraphRoundedIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="听力" />
              </ListItemButton>
              <ListItemButton onClick={() => handleNavigate('/Writing')}>
                <ListItemIcon>
                  <SettingsAccessibilityRoundedIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="写作" />
              </ListItemButton>
              <ListItemButton onClick={() => handleNavigate('/Translation')}>
                <ListItemIcon>
                  <SettingsAccessibilityRoundedIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="翻译" />
              </ListItemButton>
            </Box>
          )}
          <ListItemButton onClick={toggleTestManagement}>
            <ListItemIcon>
              <MdAssignmentAdd className="icon" />
            </ListItemIcon>
            <ListItemText primary="题库管理" />
          </ListItemButton>
          {showTestManagement && (
            <Box sx={{ pl: 4 }}>
              <ListItemButton onClick={() => handleNavigate('/RealTestManagement')}>
                <ListItemIcon>
                  <CurrencyYenRoundedIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="真题管理" />
              </ListItemButton>
              <ListItemButton onClick={() => handleNavigate('/Self_innovate')}>
                <ListItemIcon>
                  <AutoGraphRoundedIcon className="Smallicon" />
                </ListItemIcon>
                <ListItemText primary="自研题管理" />
              </ListItemButton>
            </Box>
          )}
          <ListItemButton onClick={() => handleNavigate('/Question')}>
            <ListItemIcon>
              <SettingsAccessibilityRoundedIcon className="Smallicon" />
            </ListItemIcon>
            <ListItemText primary="题库" />
          </ListItemButton>
          <ListItemButton onClick={() => handleNavigate('/QuestionPaper')}>
            <ListItemIcon>
              <SettingsAccessibilityRoundedIcon className="Smallicon" />
            </ListItemIcon>
            <ListItemText primary="试卷" />
          </ListItemButton>
        </List>
      </Paper>
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginLeft: `${drawerWidth}px`, mt: 'px', zIndex: 1 }}>
        <Toolbar />
        {children}
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;

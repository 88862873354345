import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { Toolbar, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material';
import { Role } from '../../interface/Types';
import { useAuth } from '../../hooks/useAuth';

const initialRows: GridRowsProp = [
  {
    id: 1,
    year: 2021,
    month: 12,
    level: 4,
    paper: 2,
    accuracyRate: '53',
  },
  {
    id: 2,
    year: 2021,
    month: 12,
    level: 4,
    paper: 3,
    accuracyRate: '43',
  },
  {
    id: 3,
    year: 2022,
    month: 12,
    level: 4,
    paper: 1,
    accuracyRate: '23',
  },
];

const initialQuestions: GridRowsProp = [
  {
    id: 1,
    question: '选出单词的正确词性',
    optionA: 'Falling into a routine can turn a person into an utter bore.',
    optionB: 'Falling into a routine',
    optionC: 'a person',
    optionD: 'an utter bore',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '词性辨析',
    questionType: '选词填空',
    answer: 'B',
    explanation: '',
    commonMistake: 'C',
  },
  {
    id: 2,
    question: '请选择与题干下划线处意思相近的表达',
    optionA: 'Worst of all, a cheater who doesn\'t get caught the first time usually cheats again. (Worst of all)',
    optionB: 'what\'s worse',
    optionC: 'first of all',
    optionD: 'what\'s more',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '同义替换',
    questionType: '选词填空',
    answer: 'C',
    explanation: '',
    commonMistake: 'D',
  },
  {
    id: 3,
    question: '请选择和题干内容相一致的段落',
    optionA: 'Receiving education in a private university in the nation\'s captial may change the author\'s future life and that of her family\'s.',
    optionB: 'A',
    optionC: 'B',
    optionD: 'C',
    optionE: 'D',
    isMultipleChoice: false,
    type: '句义理解',
    questionType: '信息匹配',
    answer: 'A',
    explanation: '我也不知道',
    commonMistake: 'B',
  },
];

const ReadingB: React.FC = () => {
  const [rows, setRows] = React.useState(initialRows);
  const [questions, setQuestions] = React.useState(initialQuestions);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [open, setOpen] = React.useState(false);
  const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<GridRowId | null>(null);
  const [updateQuestionGrid, setupdateQuestionGrid] = React.useState<GridRowsProp>([]);
  const { user } = useAuth();

  const handleAlert = (id: GridRowId) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = (confirmed: boolean) => {
    if (confirmed && selectedId !== null) {
      setRows(rows.filter((row) => row.id !== selectedId));
    }
    setOpen(false);
    setSelectedId(null);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to edit.');
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to save changes.');
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to delete.');
      return;
    }
    handleAlert(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const processQuestionRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setQuestions(questions.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleAddClick = () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to add.');
      return;
    }
    const id = rows.length ? rows[rows.length - 1].id + 1 : 1;
    setRows([...rows, { id, year: 0, month: 0, level: 0, paper: 0, question: '', optionA: '', optionB: '', optionC: '', optionD: '', optionE: '', isMultipleChoice: false, type: '', questionType: '', answer: '', explanation: '', commonMistake: '', accuracyRate: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'RoleName' },
    }));
  };

  const handleDetailClick = (id: number) => {
    let filteredQuestion = questions.filter((question) => question.id === id);
    if (filteredQuestion.length === 0) {
      filteredQuestion = [{
        id: id,
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        optionE: '',
        isMultipleChoice: false,
        type: '',
        questionType: '',
        article: '',
        answer: '',
        explanation: '',
        commonMistake: ''
      }];
    }
    setupdateQuestionGrid(filteredQuestion);
    setQuestionDetailOpen(true);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'year', headerName: '年', width: 100, editable: true, type: 'singleSelect', valueOptions: [2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022,2023,2024,2025,2026,2027,2028 ] },
    { field: 'month', headerName: '月', width: 100, editable: true ,type: 'singleSelect', valueOptions: [1,2,3,4,5,6,7,8,9,10,11,12] },
    { field: 'level', headerName: '级别', width: 100, editable: true ,type: 'singleSelect', valueOptions: [4,6]},
    { field: 'paper', headerName: '卷', width: 100, editable: true },
     { field: 'accuracyRate', headerName: '全站正确率(%)', width: 150, editable: true, type: 'number'},
    { 
      field: 'question_detail', 
      headerName: '题目详情', 
      width: 150, 
      editable: false,
      renderCell: (params) => {
        const id = params.row.id;
        return <Button onClick={() => handleDetailClick(id)}>查看</Button>;
      }
    },
    {
      field: '修改/删除',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key={`save-${id}`}
              label="Save"
              sx={{ color: 'primary.main' }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              key={`cancel-${id}`}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key={`edit-${id}`}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key={`delete-${id}`}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const questionColumns: GridColDef[] = [
    { field: 'question', headerName: '题干', width: 300, editable: true },
    { field: 'optionA', headerName: '选项A', width: 200, editable: true },
    { field: 'optionB', headerName: '选项B', width: 200, editable: true },
    { field: 'optionC', headerName: '选项C', width: 200, editable: true },
    { field: 'optionD', headerName: '选项D', width: 200, editable: true },
    { field: 'optionE', headerName: '选项E', width: 200, editable: true },
    { field: 'isMultipleChoice', headerName: '是否多选', width: 150, editable: true, type: 'boolean' },
    { field: 'type', headerName: '类型', width: 150, editable: true },
    { field: 'questionType', headerName: '题型', width: 150, editable: true },
    { field: 'article', headerName: '文章', width: 300, editable: true },
    { field: 'answer', headerName: '答案', width: 100, editable: true },
    { field: 'explanation', headerName: '解析', width: 300, editable: true },
    { field: 'commonMistake', headerName: '易错项', width: 150, editable: true, type: 'singleSelect', valueOptions: ['A','B','C','D'] },
  ];

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          听力题目管理
        </Typography>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
          添加新的听力题目
        </Button>
      </Toolbar>
      <Paper sx={{ width: '100%', overflowX: 'auto' }}>
        <Box sx={{ minWidth: 1200, height: 500 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onRowModesModelChange={handleRowModesModelChange}
            getRowId={(row) => row.id}
          />
        </Box>
      </Paper>
      <Dialog open={questionDetailOpen} onClose={() => setQuestionDetailOpen(false)} maxWidth="xl" fullWidth>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">题目详情</Typography>
            <Button onClick={() => setQuestionDetailOpen(false)} color="primary" sx={{ padding: '10px 20px', fontSize: '1rem' }}>
              确认
            </Button>
          </Box>
        </DialogTitle>    
        <Paper sx={{ overflowX: 'auto' }}>
          <Box sx={{ width: '100%', height: 800 }}>
            <DataGrid
              rows={updateQuestionGrid}
              columns={questionColumns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processQuestionRowUpdate}
              onRowModesModelChange={handleRowModesModelChange}
              getRowId={(row) => row.id}
            />
          </Box>
        </Paper>
        <DialogActions>
          <Button onClick={() => setQuestionDetailOpen(false)} color="primary">
            关闭
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>确认删除</DialogTitle>
        <DialogContent>
          <DialogContentText>你确定要删除这个题目吗？此操作无法撤销。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>取消</Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReadingB;
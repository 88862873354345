import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Button } from '@mui/material';
import { DataGrid, GridColDef, GridRowModesModel, GridRowsProp } from '@mui/x-data-grid';
const SetQuestion: React.FC = () => {
    const initialReadingQuestions: GridRowsProp = [
        {
          id: 1,
          question: '选出单词的正确词性',
          optionA: 'captured',
          optionB: '名词',
          optionC: '动词',
          optionD: '形容词',
          optionE: 'NA',
          isMultipleChoice: false,
          type: '词性辨析',
          questionType: '选词填空',
          article: '<p>NA</p>',
          answer: 'A',
          explanation: '<p></p>',
          commonMistake: '',
        },
        {
          id: 2,
          question: '选出单词的正确词性',
          optionA: 'hwllowrold',
          optionB: '名词',
          optionC: '动词',
          optionD: '形容词',
          optionE: 'NA',
          isMultipleChoice: false,
          type: '词性辨析',
          questionType: '选词填空',
          article: '<p>NA</p>',
          answer: 'A',
          explanation: '<p></p>',
          commonMistake: '',
        },
        {
          id: 3,
          question: '选出单词的正确词性',
          optionA: 'captured',
          optionB: '名词',
          optionC: '动词',
          optionD: '形容词',
          optionE: 'NA',
          isMultipleChoice: false,
          type: '词性辨析',
          questionType: '选词填空',
          article: '<p>NA</p>',
          answer: 'A',
          explanation: '<p></p>',
          commonMistake: '',
        },
        {
          id: 4,
          question: '选出单词的正确词性',
          optionA: 'Falling into a routine can turn a person into an utter bore.',
          optionB: 'Falling into a routine',
          optionC: 'a person',
          optionD: 'an utter bore',
          optionE: 'NA',
          isMultipleChoice: false,
          type: '词性辨析',
          questionType: '选词填空',
          article: '<p>NA</p>',
          answer: 'B',
          explanation: '<p></p>',
          commonMistake: 'C',
        },
        {
          id: 5,
          question: '请选择与题干下划线处意思相近的表达',
          optionA: 'Worst of all, a cheater who doesn\'t get caught the first time usually cheats again. (Worst of all)',
          optionB: 'what\'s worse',
          optionC: 'first of all',
          optionD: 'what\'s more',
          optionE: 'NA',
          isMultipleChoice: false,
          type: '同义替换',
          questionType: '选词填空',
          article: '<p>NA</p>',
          answer: 'C',
          explanation: '<p></p>',
          commonMistake: 'D',
        },
        {
          id: 6,
          question: '请选择和题干内容相一致的段落',
          optionA: 'Receiving education in a private university in the nation\'s captial may change the author\'s future life and that of her family\'s.',
          optionB: 'A',
          optionC: 'B',
          optionD: 'C',
          optionE: 'D',
          isMultipleChoice: false,
          type: '句义理解',
          questionType: '信息匹配',
          article: '<p>NA</p>',
          answer: 'A',
          explanation: '<p>我也不知道</p>',
          commonMistake: 'B',
        },
        {
          id: 7,
          question: '选出单词的同义词',
          optionA: 'how close ... relationships are',
          optionB: 'level of intimacy',
          optionC: 'how far ... the place is',
          optionD: 'level of significance',
          optionE: 'NA',
          isMultipleChoice: false,
          type: '同义替换',
          questionType: '仔细阅读',
          article: '<p>NA</p>',
          answer: 'B',
          explanation: '<p></p>',
          commonMistake: '',
        },
        {
          id: 8,
          question: '请根据材料选择正确答案',
          optionA: 'What is the finding of the new study?',
          optionB: 'Emotional intelligence helps people to get along with others.',
          optionC: 'Emotional intelligence is generally beneficial to its bearer.',
          optionD: 'People who are aware of others\' feelings may experience more stress.',
          optionE: 'People who are emotionally stressed may have trouble making friends.',
          isMultipleChoice: false,
          type: '推断判断',
          questionType: '仔细阅读',
          article: 'Recognizing when a friend or colleague feels sad...',
          answer: 'A',
          explanation: '<p></p>',
          commonMistake: '',
        },
        {
          id: 9,
          question: '请根据所给材料判断题干是否正确',
          optionA: 'The fish we eat is remarkable in they reproduce quickly.',
          optionB: '正确',
          optionC: '错误',
          optionD: 'NA',
          optionE: 'NA',
          isMultipleChoice: false,
          type: '句义理解',
          questionType: '仔细阅读',
          article: 'What\'s really remarkable, thought,...',
          answer: 'A',
          explanation: '<p></p>',
          commonMistake: '',
        },
      ]; 
    const initialListeningQuestions: GridRowsProp = [
        {
          id: 1,
          url: 'https://www.youtube.com/watch?v=9bZkp7q19f0',
          optionA: 'captured',
          optionB: '名词',
          optionC: '动词',
          optionD: '形容词',
          optionE: 'NA',
          isMultipleChoice: false,
          type: '仔细听力',
          answer: 'A',
          explanation: '<p></p>',
          commonMistake: '',
        },
        {
          id: 2,
          url: 'https://www.youtube.com/watch?v=9bZkp7q19f0',
          optionA: 'captured',
          optionB: '听不到',
          optionC: 'gnash',
          optionD: 'beotehe',
          optionE: 'NA',
          isMultipleChoice: false,
          type: '记笔记',
          answer: 'A',
          explanation: '<p></p>',
          commonMistake: '',
        },
      ];    
    const initialTranslationQuestions: GridRowsProp = [
        {
          id: 1,
          question: '请翻译下列文章',
          passage: 'The fish we eat is remarkable in they reproduce quickly, and they are able to adapt to changing conditions in the ocean. also remarkable is the fact that they are able to swim long distances in search of food, before, returning to the same place to lay their eggs.',
        },
        { 
          id: 2,
          question: '请翻译下列文章',
          passage: 'The fish we eat is remarkable in they reproduce quickly, and they are able to adapt to changing conditions in the ocean. also remarkable is the fact that they are able to swim long distances in search of food, before, returning to the same place to lay their eggs.',
        },
      ] 
    const initialWritingQuestions: GridRowsProp = [
        {
          id: 1,
          question: '请写一篇关于以下主题的文章',
          passage: 'The fish we eat is remarkable in they reproduce quickly, and they are able to adapt to changing conditions in the ocean. also remarkable is the fact that they are able to swim long distances in search of food, before, returning to the same place to lay their eggs.',
        },
        { 
          id: 2,
          question: '请写一篇关于以下主题的文章',
          passage: 'Why do you want to study in the US?, What do you want to study?, What do you want to do after you finish your studies?, hello world, are you there?'
        },
      ]
    const initialquestionReadingColumns: GridColDef[] = [
            { field: 'question', headerName: '题干', width: 300, editable: true },
            { field: 'optionA', headerName: '选项A', width: 200, editable: true },
            { field: 'optionB', headerName: '选项B', width: 200, editable: true },
            { field: 'optionC', headerName: '选项C', width: 200, editable: true },
            { field: 'optionD', headerName: '选项D', width: 200, editable: true },
            { field: 'optionE', headerName: '选项E', width: 200, editable: true },
            { field: 'isMultipleChoice', headerName: '是否多选', width: 150, editable: true, type: 'boolean' },
            { field: 'type', headerName: '类型', width: 150, editable: true },
            { field: 'questionType', headerName: '题型', width: 150, editable: true },
            { field: 'article', headerName: '文章', width: 300, editable: true },
            { field: 'answer', headerName: '答案', width: 100, editable: true },
            { field: 'explanation', headerName: '解析', width: 300, editable: true },
            { field: 'commonMistake', headerName: '易错项', width: 150, editable: true, type: 'singleSelect', valueOptions: ['A','B','C','D'] },
    ];
          
    const initialquestionListeningColumns: GridColDef[] = [
    { field: 'url', headerName: '链接', width: 300, editable: true },
    { field: 'optionA', headerName: '选项A', width: 200, editable: true },
    { field: 'optionB', headerName: '选项B', width: 200, editable: true },
    { field: 'optionC', headerName: '选项C', width: 200, editable: true },
    { field: 'optionD', headerName: '选项D', width: 200, editable: true },
    { field: 'optionE', headerName: '选项E', width: 200, editable: true },
    { field: 'isMultipleChoice', headerName: '是否多选', width: 150, editable: true, type: 'boolean' },
    { field: 'type', headerName: '类型', width: 150, editable: true },
    { field: 'answer', headerName: '答案', width: 100, editable: true },
    { field: 'explanation', headerName: '解析', width: 300, editable: true },
    { field: 'commonMistake', headerName: '易错项', width: 150, editable: true, type: 'singleSelect', valueOptions: ['A','B','C','D'] },
    ];
    
    const initialquestionTranslationColumns: GridColDef[] = [
    { field: 'question', headerName: '题干', width: 300, editable: true },
    { field: 'passage', headerName: '文章', width: 300, editable: true },
    ];
    
    const initialquestionWritingColumns: GridColDef[] = [
    { field: 'question', headerName: '题干', width: 300, editable: true },
    { field: 'passage', headerName: '文章', width: 300, editable: true },
    ];
    const nvigate = useNavigate();
    const location = useLocation();
    const [currentQuestionrow, setCurrentQuestionrow] = React.useState<GridRowsProp>(initialReadingQuestions);
    const [currentQuestioncolumn, setCurrentQuestioncolumn] = React.useState<GridColDef[]>(initialquestionReadingColumns)
    const { initialRows, rowModesModel } = location.state as { initialRows: GridRowsProp, rowModesModel: GridRowModesModel };

    const handleDetailClick = (type: string) => {
        let currentQuestionrow: GridRowsProp;
        let currentQuestioncolumn: GridColDef[];
    
        switch (type) {
          case '阅读':
            currentQuestionrow = initialReadingQuestions;
            currentQuestioncolumn = initialquestionReadingColumns;
            break;
          case '听力':
            currentQuestionrow = initialListeningQuestions;
            currentQuestioncolumn = initialquestionListeningColumns;
            break;
          case '翻译':
            currentQuestionrow = initialTranslationQuestions;
            currentQuestioncolumn = initialquestionTranslationColumns;
            break;
          case '写作':
            currentQuestionrow = initialWritingQuestions;
            currentQuestioncolumn = initialquestionWritingColumns;
            break;
          default:
            return;
        }
    
        nvigate('/questionDetail', {
          state: {
            initialRows: currentQuestionrow,
            initialColumns: currentQuestioncolumn,
            rowModesModel: {},
          },
        });
      };
    
      const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'type', headerName: '类型', width: 150 },
        { field: 'averagescore', headerName: '平均分', width: 150, editable: true, type: 'number' },
        {
          field: 'action',
          headerName: '题目详情',
          width: 150,
          renderCell: (params) => (
            <strong>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => handleDetailClick(params.row.type)}
              >
                详情
              </Button>
            </strong>
          ),
        },
      ];
    
      return (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            套题详情
          </Typography>
          <Paper sx={{ width: '100%', overflowX: 'auto' }}>
            <Box sx={{ minWidth: 1200, height: 500 }}>
              <DataGrid
                rows={initialRows}
                columns={columns}
                editMode="row"
                rowModesModel={{}}
                getRowId={(row) => row.id}
              />
            </Box>
          </Paper>
          <Button onClick={() => window.history.back()} color="primary" sx={{ marginTop: 2 }}>
            返回
          </Button>
        </Box>
      );
    };
    
  export default SetQuestion;
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import navigate from '@mui/material/Link';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridRowProps,
} from '@mui/x-data-grid';
import { Toolbar, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Paper, DialogContentText, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Role } from '../../../interface/Types';
import { useAuth } from '../../../hooks/useAuth';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
const initialRows: GridRowsProp = [
  { id: 1, type: '阅读理解', difficulty: '入门套题' , question_id: '1',accuracyRate: '60' },
  { id: 2, type: '听力精听', difficulty: '巩固套题' , question_id : '2',accuracyRate: '70' },
  { id: 3, type: '综合写作', difficulty: '提高套题' , question_id: '3',accuracyRate: '80' },
  { id: 4, type: '翻译', difficulty: '专项训练' , question_id : '3',accuracyRate: '90' },
  { id: 5, type: '阅读细节', difficulty: '冲刺套题' , question_id : '4',accuracyRate: '60' },
  { id: 6, type: '听力', difficulty: '高级套题' , question_id: '5',accuracyRate: '70' },
];

const initialReadingQuestions: GridRowsProp = [
  {
    id: 1,
    question: '选出单词的正确词性',
    optionA: 'captured',
    optionB: '名词',
    optionC: '动词',
    optionD: '形容词',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '词性辨析',
    questionType: '选词填空',
    article: '<p>NA</p>',
    answer: 'A',
    explanation: '<p></p>',
    commonMistake: '',
  },
  {
    id: 2,
    question: '选出单词的正确词性',
    optionA: 'hwllowrold',
    optionB: '名词',
    optionC: '动词',
    optionD: '形容词',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '词性辨析',
    questionType: '选词填空',
    article: '<p>NA</p>',
    answer: 'A',
    explanation: '<p></p>',
    commonMistake: '',
  },
  {
    id: 3,
    question: '选出单词的正确词性',
    optionA: 'captured',
    optionB: '名词',
    optionC: '动词',
    optionD: '形容词',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '词性辨析',
    questionType: '选词填空',
    article: '<p>NA</p>',
    answer: 'A',
    explanation: '<p></p>',
    commonMistake: '',
  },
  {
    id: 4,
    question: '选出单词的正确词性',
    optionA: 'Falling into a routine can turn a person into an utter bore.',
    optionB: 'Falling into a routine',
    optionC: 'a person',
    optionD: 'an utter bore',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '词性辨析',
    questionType: '选词填空',
    article: '<p>NA</p>',
    answer: 'B',
    explanation: '<p></p>',
    commonMistake: 'C',
  },
  {
    id: 5,
    question: '请选择与题干下划线处意思相近的表达',
    optionA: 'Worst of all, a cheater who doesn\'t get caught the first time usually cheats again. (Worst of all)',
    optionB: 'what\'s worse',
    optionC: 'first of all',
    optionD: 'what\'s more',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '同义替换',
    questionType: '选词填空',
    article: '<p>NA</p>',
    answer: 'C',
    explanation: '<p></p>',
    commonMistake: 'D',
  },
  {
    id: 6,
    question: '请选择和题干内容相一致的段落',
    optionA: 'Receiving education in a private university in the nation\'s captial may change the author\'s future life and that of her family\'s.',
    optionB: 'A',
    optionC: 'B',
    optionD: 'C',
    optionE: 'D',
    isMultipleChoice: false,
    type: '句义理解',
    questionType: '信息匹配',
    article: '<p>NA</p>',
    answer: 'A',
    explanation: '<p>我也不知道</p>',
    commonMistake: 'B',
  },
  {
    id: 7,
    question: '选出单词的同义词',
    optionA: 'how close ... relationships are',
    optionB: 'level of intimacy',
    optionC: 'how far ... the place is',
    optionD: 'level of significance',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '同义替换',
    questionType: '仔细阅读',
    article: '<p>NA</p>',
    answer: 'B',
    explanation: '<p></p>',
    commonMistake: '',
  },
  {
    id: 8,
    question: '请根据材料选择正确答案',
    optionA: 'What is the finding of the new study?',
    optionB: 'Emotional intelligence helps people to get along with others.',
    optionC: 'Emotional intelligence is generally beneficial to its bearer.',
    optionD: 'People who are aware of others\' feelings may experience more stress.',
    optionE: 'People who are emotionally stressed may have trouble making friends.',
    isMultipleChoice: false,
    type: '推断判断',
    questionType: '仔细阅读',
    article: 'Recognizing when a friend or colleague feels sad...',
    answer: 'A',
    explanation: '<p></p>',
    commonMistake: '',
  },
  {
    id: 9,
    question: '请根据所给材料判断题干是否正确',
    optionA: 'The fish we eat is remarkable in they reproduce quickly.',
    optionB: '正确',
    optionC: '错误',
    optionD: 'NA',
    optionE: 'NA',
    isMultipleChoice: false,
    type: '句义理解',
    questionType: '仔细阅读',
    article: 'What\'s really remarkable, thought,...',
    answer: 'A',
    explanation: '<p></p>',
    commonMistake: '',
  },
]; 


const currentQuestioncolumn: GridColDef[] = [
  { field: 'question', headerName: '题目', width: 300 },
  { field: 'optionA', headerName: '选项A', width: 150 },
  { field: 'optionB', headerName: '选项B', width: 150 },
  { field: 'optionC', headerName: '选项C', width: 150 },
  { field: 'optionD', headerName: '选项D', width: 150 },
  { field: 'optionE', headerName: '选项E', width: 150 },
  { field: 'isMultipleChoice', headerName: '多选', width: 150 },
  { field: 'type', headerName: '题型', width: 150 },
  { field: 'questionType', headerName: '题目类型', width: 150 },
  { field: 'article', headerName: '文章', width: 150 },
  { field: 'answer', headerName: '答案', width: 150 },
  { field: 'explanation', headerName: '解析', width: 150 },
  { field: 'commonMistake', headerName: '常见错误', width: 150 },
];







const Self_innovate: React.FC = () => {
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [open, setOpen] = React.useState(false);
  const [questionDetailOpen, setQuestionDetailOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<GridRowId | null>(null);
  const [updateSetQuestionGrid, setUpdateSetQuestionGrid] = React.useState<GridRowsProp>([]);
  const { user } = useAuth();

  const [handleTypeFilter, sethandleTypeFilter] = React.useState(false);
  const [selectedTypes, setSelectedTypes] = React.useState<string[]>([]);
  const [handledifficultyFilter, sethandledifficultyFilter] = React.useState(false);
  const [selecteddifficulties, setSelecteddifficulties] = React.useState<string[]>([]);

  const nvigate = useNavigate();

  const handleDetailClick = (type: string) => {
    nvigate('/questionDetail', {
      state: {
        initialRows: initialReadingQuestions,
        initialColumns: currentQuestioncolumn,
        rowModesModel: {},
      },
    });
  };
  /* 从这里开始是对DATAGRID的增删查改的所有操作 */
  const handleAlert = (id: GridRowId) => {
    setSelectedId(id);
    setOpen(true);
  };

  const handleClose = (confirmed: boolean) => {
    if (confirmed && selectedId !== null) {
      setRows(rows.filter((row) => row.id !== selectedId));
    }
    setOpen(false);
    setSelectedId(null);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to edit.');
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    if (user?.role === Role.guest) {
      alert('You do not have permission to save changes.');
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
      return;
    }
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to delete.');
      return;
    }
    handleAlert(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleAddClick = () => {
    if (user?.role !== Role.admin) {
      alert('You do not have permission to add.');
      return;
    }
    const id = rows.length ? rows[rows.length - 1].id + 1 : 1;
    setRows([...rows, { id, year: 0, month: 0, level: 0, paper: 0, question_number: '',question_type: '',question: '', optionA: '', optionB: '', optionC: '', optionD: '', optionE: '', isMultipleChoice: false, type: '', questionType: '', answer: '', explanation: '', commonMistake: '', accuracyRate: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'RoleName' },
    }));
  };





  const navigate = useNavigate();


//----------到这里结束对DATAGRID的操作--------------------------------------------------------------------------------

/* 从这里开始是对过滤年份的操作 */

  const handleOpenTypeDialog = () => {
    sethandleTypeFilter(true);
  };

  const handleCloseTypeDialog = () => {
    sethandleTypeFilter(false);
  }
  const handleTypeClick = (type: string) => {
    setSelectedTypes((prev) => {
      // 判断是否已经包含type
      const isSelected = prev.includes(type);
      // 如果包含，过滤掉type；如果不包含，添加type
      const newSelectedYears = isSelected ? prev.filter((y) => y !== type) : [...prev, type];
      return newSelectedYears;
    });
  };
  
  

  const handleFilterTypeApply = () => {
    sethandleTypeFilter(false);
    if (selectedTypes.length > 0) {
      const filteredRows = initialRows.filter((row) => selectedTypes.includes(row.type));
      setRows(filteredRows);
    } else {
      setRows(initialRows);
    }
  };
//----------到这里结束--------------------------------------------------------------------------------


/* 从这里开始是对过滤月份的操作 */
  const handledifficultyOpenDialog = () => {
    sethandledifficultyFilter(true);
  };
  const handleClosedifficultyDialog = () => {
    sethandledifficultyFilter(false);
  };

  const handledifficultyClick = (difficulty: string) => {
    setSelecteddifficulties((prev) =>
      prev.includes(difficulty) ? prev.filter((y) => y !== difficulty) : [...prev, difficulty]
    );
  };

  const handleFilterdifficultyApply = () => {
    sethandledifficultyFilter(false);
    if (selecteddifficulties.length > 0) {
      const filteredRows = initialRows.filter((row) => selecteddifficulties.includes(row.difficulty));
      setRows(filteredRows);
    } else {
      setRows(initialRows);
    }
  };




const handleCancelAllFilters = () => {
    setSelectedTypes([]);
    setSelecteddifficulties([]);
  
    setRows(initialRows);
}


  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'type', headerName: '类型', width: 100, editable: true, type: 'singleSelect', valueOptions: ['听力题', '阅读题', '单词题', '写作题','翻译题'] },
    { field: 'difficulty', headerName: '难度', width: 100, editable: true ,type: 'singleSelect', valueOptions: ['基础阶段','冲刺阶段','过渡阶段','提升阶段'] },
    { field: 'accuracyRate', headerName: '全站得分', width: 150, editable: true, type: 'number'},
    { 
      field: 'question_detail', 
      headerName: '题目详情', 
      width: 100, 
      editable: false,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => handleDetailClick(params.row.question_id)}
          >
            详情
          </Button>
        </strong>
      ),
    },
    {
      field: '修改/删除',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key={`save-${id}`}
              label="Save"
              sx={{ color: 'primary.main' }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              key={`cancel-${id}`}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            key={`edit-${id}`}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key={`delete-${id}`}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          自研题管理
        </Typography>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
          添加新的自研套题
        </Button>
      </Toolbar>
     

<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, height: 50}}>
  <Box sx={{ ml: 8, width: 100 }}>
    <Button color="primary" startIcon={<FilterAltRoundedIcon />} onClick={handleOpenTypeDialog}>
      类型
    </Button>
  </Box>
  <Box sx={{ width: 100 }}>
    <Button color="primary" startIcon={<FilterAltRoundedIcon />} onClick={handledifficultyOpenDialog}>
      难度
    </Button>
  </Box>
  <Box sx={{ flexGrow: 1 }} />
  <Box sx={{ width: 220, display: 'flex', justifyContent: 'flex-end' }}>
    <Button color="primary" startIcon={<FilterAltOffRoundedIcon />} onClick={handleCancelAllFilters}>
      取消所有过滤
    </Button>
  </Box>
</Box>


      <Paper sx={{ width: '100%', overflowX: 'auto' }}>
        <Box sx={{ minWidth: 1200, height: 500 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onRowModesModelChange={handleRowModesModelChange}
            getRowId={(row) => row.id}
          />
        </Box>
      </Paper>

      <Dialog open={handleTypeFilter} onClose={handleCloseTypeDialog} maxWidth="xl" fullWidth>
        <DialogTitle>选择你需要的类型</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {Array.from(new Set(rows.map((row) => row.type))).map((type) => (
              <Button
                key={type}
                variant={selectedTypes.includes(type) ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleTypeClick(type)}
                sx={{ margin: 1 }}
              >
                {type}
              </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTypeDialog} color="secondary">
            取消
          </Button>
          <Button onClick={handleFilterTypeApply} color="primary">
            确认
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={handledifficultyFilter} onClose={handleClosedifficultyDialog} maxWidth="xl" fullWidth>
        <DialogTitle>选择你需要的难度</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {Array.from(new Set(rows.map((row) => row.difficulty))).map((difficulty) => (
              <Button
                key={difficulty}
                variant={selecteddifficulties.includes(difficulty) ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handledifficultyClick(difficulty)}
                sx={{ margin: 1 }}
              >
                {difficulty}
              </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosedifficultyDialog} color="secondary">
            取消
          </Button>
          <Button onClick={handleFilterdifficultyApply} color="primary">
            确认
          </Button>
        </DialogActions>
      </Dialog>      
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>确认删除</DialogTitle>
        <DialogContent>
          <DialogContentText>你确定要删除这个自研套题吗？此操作无法撤销。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>取消</Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Self_innovate;

import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { QuestionCorrect, SingleChoiceCorrect, MultipleChoiceCorrect, TrueFalseCorrect, GapFillingCorrect, ShortAnswerCorrect, WordCardCorrect } from '../../interface/QuestionTypes';

interface CorrectDialogProps {
    open: boolean; // Controls whether the dialog is open or closed
    onClose: () => void; // Function to close the dialog
    correct: QuestionCorrect | null; // The correct answer details
    questionType: string | null; // The type of the question to determine how to display the correct answer
}

const CorrectDialog: React.FC<CorrectDialogProps> = ({ open, onClose, correct, questionType }) => {
    
    // Function to render the correct answer details based on the question type
    const renderCorrectDetails = () => {
        // If correct answer details or question type is missing, show a message
        if (!correct || !questionType) {
            return <p>No correct answer available</p>;
        }

        // Handle different question types and display the correct answer accordingly
        switch (questionType) {
            case 'single_choice': {
                const correctResult = (correct as { single_choice: SingleChoiceCorrect }).single_choice?.result;
                return correctResult !== undefined ? (
                    <TextField
                        label="Correct Answer"
                        value={correctResult}
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                    />
                ) : (
                    <p>No correct answer available</p>
                );
            }

            case 'multiple_choice': {
                const correctResult = (correct as { multiple_choice: MultipleChoiceCorrect }).multiple_choice?.result;
                return correctResult && correctResult.length > 0 ? (
                    correctResult.map((optionIndex, index) => (
                        <TextField
                            key={index}
                            label={`Correct Answer ${index + 1}`}
                            value={optionIndex}
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                        />
                    ))
                ) : (
                    <p>No correct answers</p>
                );
            }

            case 'true_false': {
                const correctResult = (correct as { true_false: TrueFalseCorrect }).true_false?.result;
                return correctResult !== undefined ? (
                    <TextField
                        label="Correct Answer"
                        value={correctResult ? 'True' : 'False'}
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                    />
                ) : (
                    <p>No correct answer available</p>
                );
            }

            case 'gap_filling': {
                const correctResult = (correct as { gap_filling: GapFillingCorrect }).gap_filling?.result;
                return correctResult && Object.keys(correctResult).length > 0 ? (
                    Object.keys(correctResult).map((key) => (
                        <TextField
                            key={key}
                            label={`Blank ${parseInt(key) + 1}`}
                            value={correctResult[parseInt(key)]}
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                        />
                    ))
                ) : (
                    <p>No correct answers</p>
                );
            }

            case 'short_answer': {
                const correctResult = (correct as { short_answer: ShortAnswerCorrect }).short_answer?.result;
                return correctResult !== undefined ? (
                    <TextField
                        label="Correct Answer"
                        value={correctResult}
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                    />
                ) : (
                    <p>No correct answer available</p>
                );
            }

            case 'word_card': {
                const correctResult = (correct as { word_card: WordCardCorrect }).word_card?.result;
                return correctResult !== undefined ? (
                    <TextField
                        label="Correct Answer"
                        value={correctResult}
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                    />
                ) : (
                    <p>No correct answer available</p>
                );
            }

            default:
                return <p>Invalid question type</p>; // Handle any unknown or invalid question types
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Correct Answer Details</DialogTitle>
            <DialogContent>{renderCorrectDetails()}</DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CorrectDialog;

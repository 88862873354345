import React, { useState } from 'react';
import CrudGrid from '../../components/CrudGrid';
import { fetchData, deleteData, saveData } from '../../components/ParentComponent';
import { useAuth } from '../../hooks/useAuth';
import { GridColDef } from '@mui/x-data-grid';
import ErrorPopup from '../../error/ErrorPopup';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'name', headerName: '角色名称', width: 180, editable: true },
  { field: 'description', headerName: '描述', width: 220, editable: true },
];

const AssignPage: React.FC = () => {
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const handleError = (error: string) => {
    setErrorMessage(error);
    setShowErrorPopup(true);
  };

  return (
    <div>
      {showErrorPopup && <ErrorPopup message={errorMessage} onClose={() => setShowErrorPopup(false)} />}
      <CrudGrid
        userRole={user?.role || 'guest'}
        fetchData={() => fetchData(null, '/get_roles', handleError)}
        deleteData={(id) => deleteData(id, '/delete_role', handleError)}
        saveData={(updatedRow) => saveData(updatedRow, '/create_role', '/update_role', handleError)}
        columns={columns}
        title="角色"
      />
    </div>
  );
};

export default AssignPage;



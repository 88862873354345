import React, { useEffect, useState, useRef } from "react";
import { Avatar, Box, Button, Container, createTheme, Link, TextField, ThemeProvider, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const defaulttheme = createTheme({
  palette: {}
});

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.ofodelivery.com/" target="_blank">
        AI_Study
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login: React.FC = () => {
  const { login, user } = useAuth();
  const [hasNavigated, setHasNavigated] = useState(false);
  const navigate = useNavigate();
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const initiateNavigation = async () => {
      if (user && !hasNavigated) {
        navigate('/dashboard');
        console.log("Navigate to dashboard");
        setHasNavigated(true);
      }
    };

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      initiateNavigation();
    }, 300); // Adjust the debounce delay time as needed

    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [user, hasNavigated, navigate]);

  // Get Token from local storage
  const getToken = () => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      return user.access_token;
    }
    return null;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = (data.get('username') as string) || '';
    const password = (data.get('password') as string) || '';
    console.log("Attempting to login with:", { username, password });
    login(username, password);
  };

  return (
    <ThemeProvider theme={defaulttheme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            AI BACKSTAGE
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
};

export default Login;

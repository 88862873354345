import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { Question } from '../../interface/QuestionTypes';

interface QuestionFormDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (question: Question) => void;
  initialData?: Question | null;
  mode: 'edit' | 'add';
}

const defaultQuestion: Question = {
  id: 0,
  created_at: '',
  updated_at: '',
  delete_at: '',
  question_type: 'single_choice',
  short_title: '',
  score: 0,
  grade_level: 0,
  difficult: 0,
  vip_level: 0,
  content: { single_choice: { options: ['', '', '', ''], question: '' } },
  correct: { single_choice: { result: 0 } },
  status: 'pending',
  subject_id: 0,
  name: '',
  level: 0,
  level_name: '',
};

const QuestionFormDialog: React.FC<QuestionFormDialogProps> = ({ open, onClose, onSave, initialData, mode }) => {
  const [tempFormData, setTempFormData] = useState<Question>(defaultQuestion);
  const [rawContent, setRawContent] = useState<string>(''); // Raw JSON string for content
  const [rawCorrect, setRawCorrect] = useState<string>(''); // Raw JSON string for correct answer

  useEffect(() => {
    if (initialData && mode === 'edit') {
      setTempFormData(initialData);
      setRawContent(JSON.stringify(initialData.content, null, 2)); // Initialize rawContent
      setRawCorrect(JSON.stringify(initialData.correct, null, 2)); // Initialize rawCorrect
    } else {
      setTempFormData(defaultQuestion);
      setRawContent(JSON.stringify(defaultQuestion.content, null, 2)); // Set default rawContent
      setRawCorrect(JSON.stringify(defaultQuestion.correct, null, 2)); // Set default rawCorrect
    }
  }, [initialData, mode]);

  const handleTempChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempFormData({
      ...tempFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTempSelectChange = (e: SelectChangeEvent<string>) => {
    const question_type = e.target.value as string;

    let content = {};
    let correct = {};

    if (question_type === 'single_choice') {
      content = { single_choice: { options: ['', '', '', ''], question: '' } };
      correct = { single_choice: { result: 0 } };
    } else if (question_type === 'multiple_choice') {
      content = { multiple_choice: { options: ['', '', '', ''], question: '' } };
      correct = { multiple_choice: { result: [] } };
    } else if (question_type === 'true_false') {
      content = { true_false: { question: '' } };
      correct = { true_false: { result: false } };
    } else if (question_type === 'gap_filling') {
      content = { gap_filling: { question: '', blank_count: 2 } };
      correct = { gap_filling: { result: { 0: '', 1: '' } } };
    } else if (question_type === 'short_answer') {
      content = { short_answer: { question: '' } };
      correct = { short_answer: { result: '' } };
    } else if (question_type === 'word_card') {
      content = { word_card: { words: ['', '', ''] } };
      correct = { word_card: { result: 0 } };
    }

    setTempFormData({
      ...tempFormData,
      question_type,
      content,
      correct,
    });

    // Update the raw JSON strings
    setRawContent(JSON.stringify(content, null, 2));
    setRawCorrect(JSON.stringify(correct, null, 2));
  };

  const handleSave = () => {
    try {
      // Parse the raw JSON fields only when saving
      const parsedContent = JSON.parse(rawContent);
      const parsedCorrect = JSON.parse(rawCorrect);

      const updatedFormData = {
        ...tempFormData,
        content: parsedContent,
        correct: parsedCorrect,
      };

      onSave(updatedFormData); // Save the updated form data
      onClose(); // Close the dialog
    } catch (error) {
      console.error('Invalid JSON format:', error);
      alert('There is an error in the JSON content. Please correct it before saving.');
    }
  };

  const handleStatusChange = (e: SelectChangeEvent<string>) => {
    setTempFormData({
      ...tempFormData,
      status: e.target.value,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{mode === 'edit' ? 'Edit Question' : 'Add New Question'}</DialogTitle>
      <DialogContent dividers style={{ maxHeight: '70vh', overflow: 'auto' }}>
        {mode === 'add' && (
          <FormControl fullWidth margin="dense">
            <InputLabel>Question Type</InputLabel>
            <Select
              value={tempFormData.question_type}
              onChange={handleTempSelectChange}
              label="Question Type"
            >
              <MenuItem value="single_choice">Single Choice</MenuItem>
              <MenuItem value="multiple_choice">Multiple Choice</MenuItem>
              <MenuItem value="true_false">True/False</MenuItem>
              <MenuItem value="gap_filling">Gap Filling</MenuItem>
              <MenuItem value="short_answer">Short Answer</MenuItem>
              <MenuItem value="word_card">Word Card</MenuItem>
            </Select>
          </FormControl>
        )}
        <TextField
          margin="dense"
          label="Short Title"
          name="short_title"
          value={tempFormData.short_title}
          onChange={handleTempChange}
          fullWidth
        />

        <TextField
          margin="dense"
          label="Score"
          name="score"
          type="number"
          value={tempFormData.score}
          onChange={handleTempChange}
          fullWidth
        />

        <TextField
          margin="dense"
          label="Grade Level"
          name="grade_level"
          type="number"
          value={tempFormData.grade_level}
          onChange={handleTempChange}
          fullWidth
        />

        <TextField
          margin="dense"
          label="Difficulty"
          name="difficult"
          type="number"
          value={tempFormData.difficult}
          onChange={handleTempChange}
          fullWidth
        />

        <TextField
          margin="dense"
          label="VIP Level"
          name="vip_level"
          type="number"
          value={tempFormData.vip_level}
          onChange={handleTempChange}
          fullWidth
        />

        <FormControl fullWidth margin="dense">
          <InputLabel>Status</InputLabel>
          <Select
            value={tempFormData.status}
            onChange={handleStatusChange}
            label="Status"
          >
            <MenuItem value="pending">pending</MenuItem>
            <MenuItem value="publish">publish</MenuItem>
          </Select>
        </FormControl>

        {tempFormData.question_type && (
          <>
            <TextField
              margin="dense"
              label="Question Content"
              name="content"
              value={rawContent}
              onChange={(e) => setRawContent(e.target.value)}
              fullWidth
              multiline
              rows={8}
            />
            <TextField
              margin="dense"
              label="Correct Answer"
              name="correct"
              value={rawCorrect}
              onChange={(e) => setRawCorrect(e.target.value)}
              fullWidth
              multiline
              rows={8}
            />
          </>
        )}

        <TextField
          margin="dense"
          label="Subject ID"
          name="subject_id"
          type="number"
          value={tempFormData.subject_id}
          onChange={handleTempChange}
          fullWidth
        />

        <TextField
          margin="dense"
          label="Name"
          name="name"
          value={tempFormData.name}
          onChange={handleTempChange}
          fullWidth
        />

        <TextField
          margin="dense"
          label="Level"
          name="level"
          type="number"
          value={tempFormData.level}
          onChange={handleTempChange}
          fullWidth
        />

        <TextField
          margin="dense"
          label="Level Name"
          name="level_name"
          value={tempFormData.level_name}
          onChange={handleTempChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionFormDialog;

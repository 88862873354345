import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthProvider';
import PrivateRoute from './components/routes/PrivateRoute';
import AccessControl from './components/routes/AccessControl';
import routes from './components/routes/routes';
import Login from './components/login';
import Layout from './pages/Layout';
import ErrorPopup from './error/ErrorPopup';

interface ErrorState {
  visible: boolean;
  message: string;
}

function App() {
  // State to manage the visibility and message of the error popup
  const [error, setError] = useState<ErrorState>({ visible: false, message: '' });

  // Function to show the popup with a specific message
  const showError = (message: string) => {
    setError({ visible: true, message });
  };

  // Function to hide the popup
  const hideError = () => {
    setError({ visible: false, message: '' });
  };

  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={
          <PrivateRoute>
            <Layout>
              <Routes>
                {routes.map(({ path, component: Component, exact, acl }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <AccessControl route={{ path, component: Component, exact, acl }}>
                        <Component />
                      </AccessControl>
                    }
                  />
                ))}
              </Routes>
            </Layout>
          </PrivateRoute>
        } />
      </Routes>
      {error.visible && <ErrorPopup message={error.message} onClose={hideError} />}
    </AuthProvider>
  );
}

export default App;

import React, { useState } from 'react';
import CrudGrid from '../../components/CrudGrid';
import { fetchData, deleteData, saveData } from '../../components/ParentComponent';
import { useAuth } from '../../hooks/useAuth';
import { GridColDef } from '@mui/x-data-grid';
import ErrorPopup from '../../error/ErrorPopup'; // Import the ErrorPopup component

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  { field: 'role_id', headerName: '用户ID', width: 230, editable: true },
  { field: 'permission_id', headerName: '特权ID', width: 230, editable: true }
];

const RolesAndPrivilegesPage: React.FC = () => {
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const handleError = (error: string) => {
    setErrorMessage(error);
    setShowErrorPopup(true);
  };

  return (
    <div>
      {showErrorPopup && <ErrorPopup message={errorMessage} onClose={() => setShowErrorPopup(false)} />}
      <CrudGrid
        userRole={user?.role || 'guest'}
        fetchData={() => fetchData(null, '/get_role_permissions', handleError)}
        deleteData={(id) => deleteData(id, '/delete_role_permission', handleError)}
        saveData={(TableRow) => saveData(TableRow, '/create_role_permission', '/update_role_permission', handleError)}
        columns={columns}
        title="会员"
      />
    </div>
  );
};

export default RolesAndPrivilegesPage;

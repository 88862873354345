import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Grid } from '@mui/material';

interface QuestionIdsDialogProps {
  open: boolean;
  onClose: () => void;
  questionIds: number[];
  onViewQuestion: (id: number) => void;
}

const QuestionsDialog: React.FC<QuestionIdsDialogProps> = ({ open, onClose, questionIds, onViewQuestion }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Questions Detail</DialogTitle>
      <DialogContent>
        {questionIds.map((id, index) => (
          <Grid container spacing={2} alignItems="center" key={index} sx={{ mb: 1 }}>
            <Grid item xs={8}>
              <TextField
                label={`Question ID ${index + 1}`}
                value={id}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" onClick={() => onViewQuestion(id)} fullWidth>
                View Question
              </Button>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionsDialog;
